<template>
  <div id="flag-view">
    <FlagLayout />
  </div>
</template>
  
<script>
// @ is an alias to /src
import FlagLayout from "@/components/profile/FlagLayout.vue";

export default {
  name: "FlagView",
  components: {
    FlagLayout
  },
};
</script>
  