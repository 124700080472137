<template>
    <div class="w-100 h-100 position-relative" @mouseover="this.mouseOverEffect()" @mousedown="this.displayHover = true"
        @mouseleave="this.displayHover = false">
        <!-- {{ this.windowWidth }} -->
        <FlagPreview v-bind:flag-elements="this.flag.flag_elements" :watermarkBehind="watermark" :watermark="false">

        </FlagPreview>
        <!-- <div id="drawing" style="flex-grow: 1; overflow: hidden">
            <span v-for="(chosenFlagElement, chosenFlagElementType, index) in this
              .$store.state.chosenFlagElements" v-bind:key="'drawing-' + index">
              <img v-if="chosenFlagElement !== '' && chosenFlagElement['image_url']
                " v-bind:src="this.publicPath + chosenFlagElement['image_url']" />
            </span>
            <img v-bind:src="this.watermarkPath"/>
          </div> -->

        <div v-if="this.displayHover">
            <div class="hover-flag position-absolute w-100 h-100 top-0">
                <!-- <p class="mt-3">
                    {{ this.flag.flag_name }}
                </p> -->
                <!--div class="text-center">
                    <button class="connexion-button yellow-button" v-on:click="this.shareFlag()">Partager</button>
                </div-->
                <div class="text-center">
                    <button class="connexion-button yellow-button" v-on:click="this.loadSavedFlag()">Ouvrir</button>
                </div>
                <div class="text-center">
                    <button class="connexion-button yellow-button"
                        @click="this.$emit('downloadImage', HD, ((HD || this.flag.contains_premium_elements) ? false : true), this.flag.flag_name, this.flag.flag_elements)">Télécharger</button>
                </div>
                <div class="text-center">
                    <button class="connexion-button yellow-button"
                        v-on:click="this.deleteSavedFlag()">Supprimer</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import FlagPreview from '../homePage/FlagPreview.vue';

export default {
    name: "SavedFlag",
    data() {
        return {
            publicPath: process.env.BASE_URL + "flag-elements-img/",
            displayHover: false
        };
    },
    props: {
        HD: {
            type: Boolean,
            default: false
        },
        flag: {
            type: Object,
            required: true
        },
        watermark: {
            type: Boolean,
            default: false
        },
        deleteFlagMethod: {
            type: Function,
            required: true
        }
    },
    computed: {
        windowWidth() {
            return (window.innerWidth)
        }
    },
    methods: {
        mouseOverEffect() {
            if (this.windowWidth < 1200)
                return
            this.displayHover = true
        },
        deleteSavedFlag() {
            this.deleteFlagMethod(this.flag.id);
            axios
                .post('https://we-are-pirates.herokuapp.com' + "/admin/delete_saved_flag", { id: this.flag.id })
                .then(() => {
                    // this.info = response['data'].data
                    console.log("Drapeau supprimé");
                    // this.deleteFlagMethod(this.flag.id)
                    // location.reload()
                });
        },
        loadSavedFlag() {
            if (this.displayHover == false)
                return
            // console.log("avant : " + this.$store.state.chosenFlagElements);
            this.$store.commit("loadSavedFlag", this.flag.flag_elements);
            this.$store.state.loadedFlagName = this.flag.flag_name
            // console.log("après : " + this.$store.state.chosenFlagElements);
            this.$router.push("/flag-creator");
        },
        shareFlag() {
            this.$router.push("/flag/" + this.flag.id);
        }
    },
    components: { FlagPreview }
};
</script>

<style scoped>
#drawing {
    position: relative;
    width: 250px;
    height: 250px;
    /* border: solid 1px black; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-left: auto;
    margin-right: auto;
}

#drawing img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
}

.hover-flag {
    background-color: rgba(255, 255, 255, 0.5);
}

.yellow-button {
    padding: 6px 12px;
    margin-top: 13px;
}

@media screen and (max-width: 991px) {
    .yellow-button {
        margin-top: 5px;
    }
}
</style>