<template>
  <div id="profile-view">
    <ProfileLayout/>
  </div>
</template>
  
<script>
// @ is an alias to /src
import ProfileLayout from "@/components/profile/ProfileLayout.vue";

export default {
  name: "ProfileView",
  components: {
    ProfileLayout
  },
};
</script>
  