<template>
  <div class="flag-creation-view h-100">
    <FlagCreator class="h-100"></FlagCreator>
  </div>
</template>

<script>
// @ is an alias to /src
import FlagCreator from "@/components/flagCreator/FlagCreator.vue";

export default {
  name: "FlagCreationView",
  components: {
    FlagCreator
  }
};
</script>
