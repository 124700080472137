
<template>
  <VueFinalModal
    class="flex justify-center items-center modal-we-are-pirate-fullright"
    content-class="flex flex-col max-w-xl m-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2"
  >
  <CreditBuy :modal="true"></CreditBuy>
  </VueFinalModal>
</template>

<script>
  import { VueFinalModal } from 'vue-final-modal'
  import CreditBuy from '../profile/CreditBuy.vue';
  export default {
    name: "PremiumCheckoutModal",
    components: { VueFinalModal, CreditBuy },
    data () {
    return {
    }
},

    props: {
      closeModal: {
        type: Function
      }
    },
  methods:{
  }
}
</script>
<style>

</style>