<template>
 <!-- <div class="black-overlay" v-if="showBlackOverlay">
      <p>La capture d'écran est interdite sur cette page.</p>
      <button @click="closeBlackOverlay">Fermer</button>
 </div> -->
  <div class="d-flex flex-column h-100">
    <HeaderSection></HeaderSection>
    <!-- <div class="d-none">
      <UserButtons></UserButtons>
    </div> -->
    <div id="router-view-container">
      <div class="mobile-background h-100">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
  // import UserButtons from './components/UserButtons.vue';
  import HeaderSection from './components/header/HeaderSection.vue';

  export default {
  name: "App",
  components: {
    // UserButtons,
    HeaderSection
  },
  data() {
    return {
      showBlackOverlay : false
    }
  },
  methods: {
    closeBlackOverlay(){
      this.showBlackOverlay = false;
    }
  },
  mounted() {
    // Commenter/décommenter pour charger dernière version dans le local storage
    // var localStorageCurrentFlag = null
    var localStorageCurrentFlag = localStorage.getItem('chosenFlagElements');

    if (localStorageCurrentFlag)
       (this.$store.state.chosenFlagElements = JSON.parse(localStorageCurrentFlag))

     window.addEventListener('keydown', (e) => {
      console.log(e.key)
        if (e.key === 'Meta' || e.key === 'F12') {
          e.preventDefault();
          this.showBlackOverlay = true;
        }
      });   
  },
  computed: {
    currentFlag() {
      return JSON.stringify(this.$store.state.chosenFlagElements)
    }
  },
  watch: {
    currentFlag() {
      localStorage.setItem('chosenFlagElements', this.currentFlag)
    }
  },
};
</script>

<style>
:root {
   --app-height: 100%;
}

html,
body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
}
a {
    text-decoration: none !important;
}

#app {
  font-family: 'Karla';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #54504E;
  height: 100%;
}

label {
  font-family: 'Karla';
}

.form-select:focus, .form-control:focus {
  border-color: rgb(221, 189, 60) !important;
  box-shadow: 0 0 0 0.25rem rgba(221, 189, 60,.25)  !important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#router-view-container {
  background-image: url('./assets/map-background.png');
  background-size: cover !important;

  height: calc(100vh - 107px);
  width: 100%;
  background-size: 100vw;
  flex-grow: 5;
  overflow-y: scroll;
}

#router-view-container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
#router-view-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.title-police {
  font-family: 'Cinzel Decorative';
}


.modal-sign-in > .vfm__content{
  max-width: 510px;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 16px;
  margin-top: calc(50vh - 170px) !important;
}

.modal-sign-up > .vfm__content{
  max-width: 510px;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 16px;
  margin-top: calc(50vh - 290px) !important;
}

.modal-reorder > .vfm__content{
  max-width: 587px;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 16px;
  margin-top: 35px !important;
  height: calc(100vh - 70px);
}


.connexion-button {
  font-family: 'Karla' !important;
  color: white !important;
  background-color: #1B1B1B !important;
  border: 1px solid white !important;
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
}


.connexion-button.yellow-button{
  color: #1B1B1B !important;
  background-color: #DDBD3C !important;
  border: none !important;
}
.connexion-button.white-button{
  color: #1B1B1B !important;
  background-color: white !important;
  border: 1px solid #1B1B1B !important;
}

.font-700 {
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  .mobile-background {
    background-color: rgba(255, 255, 255, 0.6);
    height: calc(100vh - 107px);
    width: 100%;
  overflow-y: scroll;

  }
  #introduction-container, .flag-exemples-container{
      background-color: transparent;
  }
}
</style>