import Utils from '../utils/utils.js';

const state = {
    isConnected: false,
    user: null,
    credits:0,
  };
  
  const mutations = {
    SET_AUTHENTICATION(state, isConnected) {
      state.isConnected = isConnected;
    },
    SET_USER(state, user) {
      state.user = user;
      if(user && user.id){
        state.credits = user.credits
      } else {
        state.credits = 0;
      }
    },
    SET_CREDITS(state, credits){
      console.log('SEEEEEEEEEEEEEEEEEEET CRDDIT')
       state.credits = credits;
    }
  };
  
  const actions = {
    async checkSession({ commit, rootState }) {
      var uniqueCode = Utils.getCookie("uniquecode");
      if(!uniqueCode)
        return;
      const $fetch = rootState.fetch; 
      let res = await $fetch.get("/user/is_connected/" + uniqueCode)
      if(res.id && res.id > 0){
        $fetch.addGlobalHeader('x-wap-token',res.unique_code);
        commit('SET_AUTHENTICATION', true);
        commit('SET_USER', res);
      }
    },
   logout({ commit , rootState }) {
      const $fetch = rootState.fetch; 
      $fetch.addGlobalHeader('x-wap-token','');
      Utils.deleteAllCookies();
      commit('SET_AUTHENTICATION', false);
      commit('SET_USER', null);
    },
  };
  
  const getters = {
    isConnected: (state) => state.isConnected,
    user: (state) => state.user,
    credits: (state) => state.credits  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };