export const emptyFlag = {
  "01 DRAPEAUX - FLAGS": { "id": 525, "name": "FLAG.BLACK.svg", "image_url": "01 DRAPEAUX - FLAGS/FLAG.BLACK.svg", "type": "01 DRAPEAUX - FLAGS" },
  "03.5 SINGLE CROSS": {},
  "03 CROIX - CROSS LEFT": {},
  "03 CROIX - CROSS RIGHT": {},
  "03 CROIX - CROSS H": {},
  "03 CROIX - CROSS V": {},
  "04 MÂCHOIRES - JAWS": {},
  "05 BARBES - BEARDS": {},
  "06 CRÂNES - SKULLS": {},
  "07 DENTS - TOOTH BOTTOM": {},
  "07 DENTS - TOOTH TOP": {},
  "10 NEZ - NOSES": {},
  "08 ORBITES - ORBITS LEFT": {},
  "08 ORBITES - ORBITS RIGHT": {},
  "09 YEUX - EYES LEFT": {},
  "09 YEUX - EYES RIGHT": {},
  "12 EXTRA FACE": {},
  "11 MOUSTACHES - MUSTACHES": {},
  "13 MASQUES FACIALS - FACE MASKS": {},
  "14 LUNETTES _ MASQUES - GLASSES _ MASK": {},
  "15 CHEVEUX - HAIRS": {},
  "16 CHAPEAUX CASQUE  - HATS HELMETS": {},
  "18 EXTRA FRONT": {},
  "17 CORNES - HORNS LEFT": {},
  "17 CORNES - HORNS RIGHT": {},
  "02 EXTRA BACK": {},
}