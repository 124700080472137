<template>
  <div>
    <PremiumCheckoutModal v-model="this.$store.state.displayCreditModal" v-bind:closeModal="this.closeCreditModal">
    </PremiumCheckoutModal>
    <SignUpModal v-model="this.displaySignUpModal" v-bind:closeModal="this.closeSignUpModal"></SignUpModal>
    <SignInModal v-model="this.$store.state.displaySignInModal" v-bind:closeModal="this.closeSignInModal"
      v-bind:openSignUp="openSignUpModal"></SignInModal>
    <!-- <MenuModal v-model="this.displayMenuModal" v-bind:closeModal="this.closeMenuModal"></MenuModal> -->
    <div id="header-section">
      <h1 class="title" v-on:click="this.$router.push('/')">
        <!-- WeArePirates -->
        <img style="margin-top: -20px; height: 90px;" v-bind:src="logoPath">
      </h1>
      <div class="mobile d-block d-xl-none">
        <!--ajouter condition taille d'écran-->
        <div class="hamburger-icon">
          <span v-if="!this.displayMobileMenu" v-on:click="this.displayMobileMenu = true">
            <i class="bi bi-list d-block d-xl-none" style="color: white"></i>
          </span>
          <div v-else>
            <i class="bi bi-x d-block d-xl-none" style="color: white" v-on:click="this.displayMobileMenu = false"></i>
          </div>
        </div>
        <div class="mobile-menu" v-if="this.displayMobileMenu">
          <div class="empty-part" v-on:click="this.displayMobileMenu = false"></div>
          <div class="buttons d-relative">
            <button v-on:click="
      this.$router.push('/');
    this.displayMobileMenu = false;
    " v-bind:class="this.currentMenuClass('/')">
              ACCUEIL
            </button>
            <router-link v-if="this.currentRouteName !== 'flag-creator'" to="/flag-creator">
              <button v-if="this.termsAndConditionsAccepted" v-bind:class="this.currentMenuClass('/flag-creator')"
                v-on:click="this.displayMobileMenu = false">
                CRÉER UN DRAPEAU
              </button>
            </router-link>
            <span v-if="this.$store.state.connectedUserUniqueCode != null">
              <router-link v-if="this.currentRouteName !== 'credit'" to="/credit">
                <button v-bind:class="this.currentMenuClass('/credit')" v-on:click="this.displayMobileMenu = false">
                  <span class="counter">{{ credits }}</span>
                  MES CRÉDITS
                </button>
              </router-link>
              <router-link v-if="this.currentRouteName !== 'profile'" to="/profile">
                <button v-bind:class="this.currentMenuClass('/profile')" v-on:click="this.displayMobileMenu = false">
                  MON COMPTE
                </button>
              </router-link>
              <button v-on:click="
                this.disconnect();
                this.displayMobileMenu = false;
              ">
                DECONNEXION
              </button>
            </span>
            <span v-else>
              <button v-on:click="
      this.inscription();
    this.displayMobileMenu = false;
    ">
                CRÉER UN COMPTE
              </button>
              <button v-on:click="
      this.connexion();
    this.displayMobileMenu = false;
    ">
                CONNEXION
              </button>
            </span>
            <router-link to="/about">
              <button v-on:click="this.displayMobileMenu = false">
                A PROPOS
              </button>
            </router-link>
            <router-link to="/faq">
              <button v-on:click="this.displayMobileMenu = false">FAQ</button>
            </router-link>
            <div class="position-absolute bottom-0" style="margin-left: 29px">
              <span class="chose-lang" v-for="(lang, i) in langs" :key="`Lang${i}`">
                <!-- <input type="radio" v-bind:id="lang" v-bind:value="lang" v-model="$i18n.locale" /> -->
                <button class="d-inline" style="margin-left: 0" v-bind:class="this.langClass(lang)"
                  v-on:click="$i18n.locale = lang">
                  {{ lang.toUpperCase() }}
                </button>
                <span v-if="i < this.langs.length - 1">|</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons nav-header">
        <span class="d-none d-xl-block" style="margin-right: 26px">
          <button v-on:click="this.$router.push('/')" v-bind:class="this.currentMenuClass('/')">
            ACCUEIL
          </button>

          <router-link class="flag-creator-button" v-if="this.currentRouteName !== 'flag-creator'" to="/flag-creator">
            <button v-if="this.termsAndConditionsAccepted" v-bind:class="this.currentMenuClass('/flag-creator')">
              CRÉER UN DRAPEAU
            </button>
          </router-link>
          <span v-if="this.$store.state.connectedUserUniqueCode != null">
            <router-link v-if="this.currentRouteName !== 'credit'" to="/credit">
              <button v-bind:class="this.currentMenuClass('/credit')">
                <span class="counter">{{ credits }}</span>
                MES CRÉDITS
              </button>
            </router-link>
            <router-link v-if="this.currentRouteName !== 'profile'" to="/profile">
              <button v-bind:class="this.currentMenuClass('/profile')">
                MON COMPTE
              </button>
            </router-link>
            <button v-on:click="this.disconnect">DECONNEXION</button>
          </span>
          <span v-else>
            <button v-on:click="this.inscription">CRÉER UN COMPTE</button>
            <button v-on:click="this.connexion">CONNEXION</button>
          </span>
          <router-link to="/about">
            <button>A PROPOS</button>
          </router-link>
          <router-link to="/faq">
            <button>FAQ</button>
          </router-link>

          <span class="chose-lang" v-for="(lang, i) in langs" :key="`Lang${i}`">
            <!-- <input type="radio" v-bind:id="lang" v-bind:value="lang" v-model="$i18n.locale" /> -->
            <button v-bind:class="this.langClass(lang)" v-on:click="$i18n.locale = lang">
              {{ lang.toUpperCase() }}
            </button>
            <span v-if="i < this.langs.length - 1">|</span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SignInModal from "../modals/SignInModal.vue";
import SignUpModal from "../modals/SignUpModal.vue";
import PremiumCheckoutModal from '../modals/PremiumCheckoutModal.vue';
import { mapGetters } from 'vuex';
// import MenuModal from '../modals/MenuModal.vue';

export default {
  name: "HeaderSection",
  components: { SignInModal, SignUpModal, PremiumCheckoutModal },
  data() {
    return {
      //nbCredit: 0,
      displaySignUpModal: false,
      displayMobileMenu: false,
      logoPath: process.env.BASE_URL + "logo watermark/LOGO WAP HORIZONTALE.png",
      langs: ["fr", "en"],
      // isConnected: false
    };
  },
  mounted() {
    var uniqueCode = this.getCookie("uniquecode");
    console.log('mounted')
    if (uniqueCode) this.isConnected(uniqueCode);
  },

  computed: {
    termsAndConditionsAccepted() {
      if (localStorage.getItem("conditions")) return true;
      return false;
    },
    currentRouteName() {
      return this.$route.name;
    },
    shoudlDisplayMobileMenu() {
      if (this.displayMobileMenu == true) return true;
      return false;
    },
    ...mapGetters(['credits'])
  },
  methods: {
    currentMenuClass(path) {
      if (path === this.$route.path) {
        return "selected";
      }
      return "";
    },
    closeSignInModal() {
      this.$store.state.displaySignInModal = false;
    },
    closeSignUpModal() {
      this.displaySignUpModal = false;
    },
    closeCreditModal() {
      this.$store.state.displayCreditModal = false;
    },
    openSignUpModal() {
      this.displaySignUpModal = true;
      this.$store.state.displaySignInModal = false;
    },
    isConnected(uniqueCode) {
      console.log("isConnected");
      axios
        .get(
          'https://we-are-pirates.herokuapp.com' + "/user/is_connected/" + uniqueCode
        )
        .then((response) => {
          if (response["data"] !== "no") {
            console.log(response["data"])
            this.$store.state.fetch.addGlobalHeader('x-wap-token', response["data"].unique_code);
            this.$store.state.connectedUserUniqueCode = uniqueCode;
            this.$store.state.connectedUser = response["data"];
            this.$store.commit('SET_CREDITS', response["data"].credits)
            //this.nbCredit = response["data"].credits;
            this.$store.state.isConnected = true;
          } else {
            console.log("reset cookies");
            this.deleteAllCookies();
          }
        });
    },
    inscription() {
      this.displaySignUpModal = true;
    },
    connexion() {
      this.$store.state.displaySignInModal = true;
    },
    disconnect() {
      console.log('logout')
      this.$store.dispatch('logout');
      this.$store.state.isConnected = false;
      this.$store.state.connectedUserUniqueCode = null;
      this.nbCredit = 0;
      this.$router.push("/");
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    deleteAllCookies() {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    langClass(lang) {
      if (this.$i18n.locale == lang) return "fw-bold";
      return "";
    },
  },
};
</script>

<style scoped>
#header-section {
  height: 107px;
  background-color: #1b1b1b;
  position: relative;
}

.title {
  color: white;
  font-family: "Cinzel Decorative";
  padding-top: 29px;
  padding-left: 68px;
  font-size: 45px;
  cursor: pointer;
  width: fit-content;
}

.chose-lang button {
  margin-right: 0 !important;
}

@media screen and (max-width: 600px) {
  .title {
    padding-left: 20px;
    padding-top: 40px;
    font-size: 27px;
  }
}

.buttons {
  position: absolute;
  right: 0;
  top: 41px;
}

.buttons button {
  margin-right: 26px;
  color: white;
  background-color: #1b1b1b;
  border: none;
  font-size: 1.266rem;
}

.mobile-menu .buttons button {
  margin-right: 0px;
}

button.selected {
  font-weight: 600;
}

.mobile .hamburger-icon {
  position: absolute;
  right: 30px;
  top: 20px;
}

.mobile .hamburger-icon .bi {
  font-size: 50px;
}

.mobile .mobile-menu {
  position: absolute;
  top: 106px;
  right: 0;
  width: 100vw;
  height: calc(100vh - 106px);
  z-index: 10;
  display: flex;
}

.mobile .mobile-menu .empty-part {
  height: 100%;
  flex-grow: 1;
}

.mobile .mobile-menu .buttons {
  width: 265px;
  height: 100%;
  top: 0;
  background-color: #1b1b1b;
  z-index: 10;
}

.mobile-menu button {
  display: block;
  margin-bottom: 48px;
  margin-top: 28px;
  margin-left: 26px;
  padding-right: 0;
  font-size: 22px;
}

/* .mobile-menu .chose-lang {
    margin-left: 29px;
} */

@media screen and (max-width: 1392px) {
  .flag-creator-button {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  #header-section {
    height: 60px;
  }

  .mobile .mobile-menu {
    top: 59px;
    height: calc(100vh - 59px);
  }

  .mobile .hamburger-icon {
    /* position: absolute; */
    /* right: 30px; */
    top: 0px;
  }

  .mobile .hamburger-icon .bi {
    font-size: 43.5px;
  }

  .title img {
    margin-top: -47px !important;
    height: 50px !important;
  }
}
</style>