<template>
  <div id="save-flag-view">
    <PreviewCompletedFlag></PreviewCompletedFlag>
    <SaveFlag></SaveFlag>
  </div>
</template>

<script>
import PreviewCompletedFlag from '@/components/flagCreator/PreviewCompletedFlag.vue';
import SaveFlag from '@/components/saveFlag/SaveFlag.vue';


export default {
    name: "SaveFlagView",
    components: { SaveFlag, PreviewCompletedFlag }
}

</script>