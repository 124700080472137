<template lang="">
    <div>
        Flagcheckout
        <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="v => loading = v"
    />
    <button @click="submit">Pay now!</button>
    </div>
</template>
<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  name: "FlagCheckout",
  components: {
    StripeCheckout,
  },
  data () {
    this.publishableKey = "pk_test_51H2jTDFhb6ZVAaddOF0ZkWSM42PrWnDi2vEEu6VU4qG8IvpwMtHpCLfxUf1cBRXcS86sisfzRG4IH5VwEJr43Vha00gxt6fVc0";
    return {
      loading: false,
      lineItems: [
        {
          price: 'price_1NXjqhFhb6ZVAaddkJBBlRD8', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: 'http://localhost:8081/#/',
      cancelURL: 'http://localhost:8081/#/flag-creator',
    };
  },
  methods: {
    submit () {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>
<style lang="">
</style>