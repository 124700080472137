<template>
  <h2 class="mb-5">Acheter des crédits
    <button class="btn" v-if="btnClose" @click="hideStripe">Fermer</button>
  </h2>
  <p>Crée ton drapeau pirate avec notre collection d'éléments, dont 30% sont gratuits ! Pour débloquer le reste, il te
    suffit d'acheter des crédits : 1 crédit pour un drapeau de qualité standard, 2 crédits pour un drapeau premium.
    Explore tout l'univers de We Are Pirates grâce à ces crédits</p>
  <span v-if="showStripe == 0">
    <div class="pack-credit-list">

      <div class="pack-credit-item" v-on:click="this.selectPack(1)" :class="{ 'selected': nb === 1 }">
        <h3>Pack Marin d'eau douce</h3>
        <div class="pack-nb-credit"> 1 credit </div>
        <div class="pack-price">1€</div>
      </div>
      <div class="pack-credit-item" v-on:click="this.selectPack(2)" :class="{ 'selected': nb === 2 }">
        <h3>Pack Pirate</h3>
        <div class="pack-nb-credit"> 2 credits </div>
        <div class="pack-price">2€</div>
      </div>
      <div class="pack-credit-item" v-on:click="this.selectPack(5)" :class="{ 'selected': nb === 5 }">
        <div class="pack-discount">-20%</div>
        <h3>Pack Grand Corsaire</h3>
        <div class="pack-nb-credit"> 5 credits </div>
        <div class="pack-price">3.99€</div>
      </div>
      <div class="pack-credit-item pack-credit-best-choice" v-on:click="this.selectPack(10)"
        :class="{ 'selected': nb === 10 }">
        <div class="pack-discount">-30%</div>
        <h3>Pack Empereur</h3>
        <div class="pack-nb-credit"> 10 credits </div>
        <div class="pack-price">6.99€</div>
      </div>
      <div class="pack-credit-item" v-on:click="this.selectPack(20)" :class="{ 'selected': nb === 20 }">
        <div class="pack-discount">-50%</div>
        <h3>Pack Roi des Pirates</h3>
        <div class="pack-nb-credit"> 20 credits </div>
        <div class="pack-price">9.99€</div>
      </div>
    </div>
    <input v-if="this.$store.state.connectedUserUniqueCode != null" :disabled="lockSubmit"
      class="btn btn-buy btn-primary shadow-sm" type="submit" value="Acheter mon pack" v-on:click.prevent="purchase" />
    <center>
      <div v-if="!this.$store.state.connectedUserUniqueCode">
        <h3>Intéressé par acheté des crédits ?</h3>
        <button class="btn btn-buy btn-primary shadow-sm" v-on:click="
      this.$store.state.displaySignInModal = true;
    ">
          CONNEXION
        </button>
      </div>
      <div class="link" v-on:click.prevent=" this.$store.state.displayCreditModal = false;">Fermer</div>
    </center>
  </span>
  <div ref="card">
    <!-- A Stripe Element will be inserted here. -->
  </div>
  <span v-if="showStripe == 1">
    <span v-if="modal && waitingStripe == 1">
      <div id="wait-checkout">Traitement....</div>
    </span>
    <div id="checkout">
    </div>

  </span>
</template>

<script>
/* global Stripe */

export default {
  name: "CreditBuy",
  data() {
    return {
      spk: process.env.VUE_APP_STRIPE_PK,
      stripe: undefined,
      mounted: false,
      card: undefined,
      msg: 'Donate Here',
      payAmount: "$10.00",
      showStripe: 0,
      waitingStripe: 0,
      nb: '',
      lockSubmit: false,
      btnClose: false,
      checkout: false
    }
  },

  props: {
    closeModal: {
      type: Function
    },
    modal: Boolean
  },

  beforeUnmount() {
    if (this.checkout)
      this.checkout.destroy()
  },
  methods: {
    go() {
      var self = this;
      self.stripe = Stripe(self.spk);
    },
    hideStripe() {
      this.showStripe = 0;
    },
    async purchase() {
      if (this.nb < 1) {
        alert('Merci de sélectionner votre pack')
        return;
      }
      this.waitingStripe = 1;
      this.showStripe = 1;
      const session = await this.$store.state.fetch.post('/stripe/create-checkout-session', { body: { credit: this.nb, return_url: 'https://wearepirates.net/' } });
      let clientSecret = session.clientSecret;

      this.checkout = await this.stripe.initEmbeddedCheckout({
        clientSecret,
        onComplete: this.handleComplete
      });
      // Mount Checkout
      this.checkout.mount('#checkout');
    },
    async handleComplete() {
      this.waitingStripe = 0;
      this.btnClose = true;
      let nbCredit = this.$store.state.connectedUser.credits + this.nb;
      this.$store.state.connectedUser.credits += nbCredit;
      this.$store.commit('SET_CREDITS', nbCredit)
    },

    async processTransaction(transactionToken) {
      //var self=this;
      let dt = {
        amount: 200,
        currency: "USD",
        description: "something to say",
        token: transactionToken,
      }
      //const session = await this.$store.state.fetch.post('/stripe/create-checkout-session', { body: { credit: this.nb } });
      const payment = await this.$store.state.fetch.post('/stripe/charge', { body: { dt } });
      console.log(payment);
    },
    stripCurrency(val) {
      return val.replace(',', '').relpace('$', '').replace('.', '')
    },
    selectPack(nb) {
      if (!this.$store.state.connectedUserUniqueCode)
        return;
      this.nb = nb;
      if (!this.mounted) {
        this.go();
      }
    },
    onSuccess() {
      console.log('Payment successful!');
    },
    onError() {
      console.error('Payment failed!');
    },
  }
}
</script>
<style></style>