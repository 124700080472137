<template>
  <div class="about-view">
    <div class="about-view-container">
      <h1 class="title-police">À Propos de We Are Pirates</h1>
      <p>
        Bienvenue sur We Are Pirates, le repaire en ligne où tout amateur de piraterie peut enfin donner vie à son propre drapeau pirate ! We Are Pirates est née de notre désir ardent de posséder, tout comme Edward Teach alias Barbe Noire, Jack Rackham, Luffy et bien d'autres flibustiers, notre propre Jolly Roger ! Confronté à l'absence de solutions rapides sur la toile, l'idée de créer le premier site de création de drapeaux pirates personnalisés a commencé à germer, et c’est ainsi qu’est né We Are Pirates !
      </p>
      <h1 class="title-police">Notre Mission et Vision</h1>
      <p>
        Chez We Are Pirates, notre mission est d'offrir à tous les Ratpis, à tous les passionnés d'histoire de flibusterie, ainsi qu'à chaque fan de One Piece ou de Pirates des Caraïbes, la possibilité de posséder son propre Jolly.
      </p>
      <h1 class="title-police">Éléments et Crédits</h1>
      <p>
        Pour créer ton propre drapeau pirate, nous mettons à ta disposition une collection impressionnante de 350 éléments ! Et le meilleur ? 30% de ces éléments sont entièrement gratuits ! Les autres sont accessibles mais verrouillés pour le téléchargement. Pas de panique ! Pour débloquer ces trésors cachés, il te suffit de te rendre sur l’onglet “Mes crédits” et de cliquer sur “Acheter des crédits” ou de valider ton drapeau puis d'acquérir des crédits. Tu y trouveras également les tarifications. Grâce à ces crédits, le monde entier de We Are Pirates s'ouvrira à toi. 
        <br><br>
        1 drapeau <b>qualité standard</b> = 1 Crédit
        <br><br>
        1 drapeau <b>qualité premium</b> = 2 Crédits
      </p>
      <h1 class="title-police">Notre Engagement Qualité téléchargement : </h1>
      <p>
        We Are Pirates propose deux options de qualité de téléchargement pour vos Jolly Roger.
        <br><br>
        <b>Basse Qualité :</b>
        <br>
        Nous proposons une résolution de 72 pixels par pouce (ppp) pour une utilisation optimale sur les réseaux sociaux ou comme fond d'écran de téléphone. Les fichiers sont disponibles au format PNG, en 800x600 pixels
        <br><br>
        <b>Haute Qualité :</b>
        <br>
        Pour une qualité supérieure et une clarté optimale, nous proposons une résolution de 300 pixels par pouce (ppp). Les fichiers sont au format PNG, offrant une qualité sans perte de données et une compression minimale pour conserver tous les détails de l'image. Les tailles d'image sont de 3000x2000 pixels, parfaites pour l'impression sur divers supports physiques.
        <br><br>
        Quel que soit votre choix, We Are Pirates veille à ce que vos Jolly Roger soient à la hauteur de vos attentes, que ce soit pour une utilisation en ligne ou hors ligne.
      </p>

      <h1 class="title-police">L’Équipage :</h1>
      <p>
        <b>Thomas Amusan dit Pascal Cefran - PDG et Fondateur aka Capitaine</b> : J'ai un rêve, celui que tous les pirates du monde entier puissent arborer fièrement leur propre Jolly Roger ! We are pirates!
        <br><br>
        <b>Leex - Graphic designer / Illustrateur aka Maître Cook</b> : Toujours entrain de concocter de nouveaux éléments dans sa cuisine
        <br><br>
        <b>Antoine Alis - Développeur aka Le charpentier</b> : Responsable de la construction, de l'entretien et de la réparation du navire We Are Pirates, suuuuper !
        <br><br>
        <b>Clémence Poitras - UX/UI aka La navigatrice</b> : C’est elle qui trace les cartes ! 
      </p>
      <h1 class="title-police">CGV - CGU :</h1>
      <p>
        Pour retrouver les Conditions Générales d’Utilisation et les Conditions Générales de Vente (CGV CGU)
        <router-link to="/cgvcgu"> Clique ici !</router-link>
      </p>
      <h1 class="title-police">Contacte-nous :</h1>
      <p>
        <img :src="gmail" style="width: 25px; margin-right: 5px">
        <a href="mailto:wearepirates.cie@gmail.com">wearepirates.cie@gmail.com</a>
        <br><br>
        <img :src="instagram" style="width: 25px; margin-right: 5px">
        <a href="https://www.instagram.com/wearepirates_cie/">We Are Pirates Instagram</a>
        <br><br>
        <img :src="facebook" style="width: 25px; margin-right: 5px">
        <a href="https://www.facebook.com/profile.php?id=61551106582775">We Are Pirates Facebook</a>
        <br><br>
        <img :src="x" style="width: 25px; margin-right: 5px">
        <a href="https://twitter.com/WeArePiratesCie">We Are Pirates X (Twitter)</a>
        <br><br>
        N'hésite pas à nous contacter pour toute question. Sois le bienvenu à bord !
      </p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "AboutView",
  data() {
    return({
      gmail: require('../assets/Gmail.png'),
      instagram: require('../assets/Instagram.png'),
      facebook: require('../assets/Facebook.png'),
      x: require('../assets/x.jpg'),
    })
  }
  // components: {
  //   ProfileLayout
  // }
};
</script>

<style>
.about-view {
  margin: 56px;
}

.about-view-container {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  max-width: 1616px;
  /* margin: 56px; */
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

@media screen and (max-width: 991px) {
  .about-view-container {
    background-color: transparent;
    padding: 0;
  }

  .about-view {
    margin: 28px;
  }
}
</style>