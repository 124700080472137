<template>
  <div class="faq-view">
    <div class="faq-view-container">
      <h1 class="title-police">FAQ</h1>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Est-ce que je peux profiter du site sans me prendre la tête à créer un compte ?</template>
        <template v-slot:answer>Bien sûr ! Tu peux explorer le site et laisser libre cours à ton imagination pour réaliser un max de drapeaux sans avoir à créer un compte. Mais bon, si tu veux sauvegarder et télécharger tes créations, fais pas le timide, inscris-toi. C'est rapide et easy !</template>
      </QuestionWithHiddenAnswer>

      <QuestionWithHiddenAnswer>
        <template v-slot:question>Comment je fais mon drapeau ?</template>
        <template v-slot:answer>Sur la page "Créer un Drapeau", choisis parmi les 30% d'éléments gratuits ou les 70% d'éléments payants. Pour sélectionner un élément, clique d'abord sur la catégorie de ton choix, puis sur l'élément désiré. Si tu choisis un ou plusieurs éléments gratuits, aucun souci. En revanche, si tu optes pour un élément payant, un filigrane sera superposé à la tête de mort et une fenêtre s'affichera avec les options d'achat de crédits et les tarifs. Pas d'inquiétude, tu peux simplement fermer cette fenêtre et continuer à créer ton drapeau. L'élément sélectionné apparaîtra sur la toile. Ajoute d'autres éléments pour compléter ton Jolly Roger ! Lorsque tu es satisfait de ta création, clique sur "Valider" pour la télécharger.</template>
      </QuestionWithHiddenAnswer>

      <QuestionWithHiddenAnswer>
        <template v-slot:question>Est-ce que je peux changer l’ordre des éléments ?</template>
        <template v-slot:answer>Oui ! Pour cela, il faut cliquer sur “réorganiser les éléments” pour ouvrir la fenêtre qui te permettra de supprimer, faire passer en dessous ou au-dessus le ou les éléments de ton choix.</template>
      </QuestionWithHiddenAnswer>

      <QuestionWithHiddenAnswer>
        <template v-slot:question>Qu’indiquent les cadenas ?</template>
        <template v-slot:answer>
          Les cadenas affichés sur les éléments des catégories indiquent les éléments payants. Si les coffres sont fermés, cela veut dire que tu n'as pas assez de crédits pour les télécharger. En revanche, s'ils sont ouverts, tu as suffisamment de crédits pour les utiliser.
          <br><br>
          Si un élément n'a pas de coffre, cela signifie qu'il est accessible gratuitement. Tu peux l'utiliser pour ton Jolly Roger et le télécharger sans frais.
          <br><br>
          Sur la toile, si le coffre est fermé, cela veut dire que ton Jolly Roger contient des éléments payants pour lesquels tu n'as pas assez de crédits pour les obtenir. Mais s'il est ouvert, cela signifie que tu as les crédits nécessaires pour les télécharger.
        </template>
      </QuestionWithHiddenAnswer>

      <QuestionWithHiddenAnswer>
        <template v-slot:question>Comment je fais pour acheter des crédits ?</template>
        <template v-slot:answer>
          Il y a trois façons d'accéder au bouton "Acheter des crédits" : 
          <br>
          - Tu peux le trouver en utilisant la barre de menu et en naviguant vers l'onglet "Mes crédits".
          <br>
          - Lorsque tu valides ton drapeau, tu es redirigé vers la page "Mon drapeau" où se trouve le bouton "Acheter des crédits".
          <br>
          - Ou bien, lorsque tu sélectionnes pour la première fois un élément payant, une fenêtre apparaît avec les options de tarification ainsi que le bouton "Acheter des crédits".
        </template>
      </QuestionWithHiddenAnswer>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Et si je n’ai pas assez de crédits, qu’est-ce qui se passe ?</template>
        <template v-slot:answer>Si t'as plus ou pas assez de crédits pour choper ton drapeau, le pop-up de paiement s’affiche lors de chaque action payante. Achète ou rachète des crédits et c'est bon :)</template>
      </QuestionWithHiddenAnswer>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Où est-ce que je peux voir combien de crédits j’ai sur mon compte ?</template>
        <template v-slot:answer>D'un simple coup d'œil, tu peux voir ton nombre de crédits à côté de "Mes Crédits" dans l'en-tête ou le menu.Pour obtenir plus de détails, clique sur "Mes crédits".</template>
      </QuestionWithHiddenAnswer>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Comment je télécharge mon drapeau ?</template>
        <template v-slot:answer>Valide ton Jolly Roger, puis sur la page "Mon Drapeau", choisis la qualité de ton choix, haute ou basse, et clique sur "Télécharger". Si tu as des crédits sur ton compte, le téléchargement se lance.</template>
      </QuestionWithHiddenAnswer>
      <!-- <h5 class="mt-4 mb-3 fw-bold">Introduction</h5> -->
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Quels formats de fichiers sont disponibles ?</template>
        <template v-slot:answer>Chez We Are Pirates, tu as le choix entre deux qualités pour télécharger tes Jolly Roger. La qualité standard offre des fichiers au format PNG en 800x600 pixels avec une résolution de 72 ppp, parfaits pour les réseaux sociaux ou les fonds d'écran de téléphone. En optant pour la Haute Qualité, tu profites de fichiers PNG de 3000x2000 pixels avec une résolution de 300 ppp, idéale pour une clarté optimale et une impression sur divers supports.</template>
      </QuestionWithHiddenAnswer>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Est-ce que je peux retrouver et télécharger de nouveau mon drapeau ?</template>
        <template v-slot:answer>Si tu perds ton Jolly Roger, ne t'inquiète pas, tu peux facilement le retrouver sur ta page "Mon Compte". À partir de cette page, tu as également la possibilité de le re-télécharger. Cependant, si tu effectues des modifications sur ton Jolly Roger et que celui-ci contient des éléments payants, ou si tu ajoutes de nouveaux éléments payants, tu devras à nouveau utiliser des crédits pour ces actions.</template>
      </QuestionWithHiddenAnswer>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Besoin de parler avec les pirates de We Are Pirates ?</template>
        <template v-slot:answer>
          <br>
          <img :src="gmail" style="width: 25px; margin-right: 5px">
          <a href="mailto:wearepirates.cie@gmail.com">wearepirates.cie@gmail.com</a>
          <br><br>
          <img :src="instagram" style="width: 25px; margin-right: 5px">
          <a href="https://www.instagram.com/wearepirates_cie/">We Are Pirates Instagram</a>
          <br><br>
          <img :src="facebook" style="width: 25px; margin-right: 5px">
          <a href="https://www.facebook.com/profile.php?id=61551106582775">We Are Pirates Facebook</a>
          <br><br>
          <img :src="x" style="width: 25px; margin-right: 5px">
          <a href="https://twitter.com/WeArePiratesCie">We Are Pirates X (Twitter)</a>
          <br><br>
          N'hésite pas à nous contacter pour toute question. Sois le bienvenu à bord !
        </template>
      </QuestionWithHiddenAnswer>
    </div>
  </div>
</template>
  
<script>
import QuestionWithHiddenAnswer from "@/components/FAQ/QuestionWithHiddenAnswer.vue";

export default {
  name: "FAQView",
  components: { QuestionWithHiddenAnswer },
  data() {
    return({
      gmail: require('../assets/Gmail.png'),
      instagram: require('../assets/Instagram.png'),
      facebook: require('../assets/Facebook.png'),
      x: require('../assets/x.jpg'),
    })
  }
};
</script>

<style>
.faq-view {
  margin: 56px;
}

.faq-view-container {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  max-width: 1616px;
  /* margin: 56px; */
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

@media screen and (max-width: 991px) {
  .faq-view-container {
    background-color: transparent;
    padding: 0;
  }

  .faq-view {
    margin: 28px;
  }
}
</style>