
<template>
  <VueFinalModal class="flex justify-center items-center modal-reorder"
    content-class="flex flex-col max-w-xl m-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2">
    <div class="h-100 position-relative d-flex flex-column">
      <h2 class="mb-4 title-police" style="font-size: 22px;">Réorganiser les éléments</h2>
      <!-- {{ this.flagElements }} -->
      <div style="overflow: auto;">
        <draggable tag="ul" :list="flagElements" class="list-group" handle=".handle" item-key="name" ghost-class="ghost"
          @start="dragging = true" @end="dragging = false">
          <template #item="{ element, index }">
            <li class="list-group-item d-flex border-0 flex-wrap">
              <img class="handle" v-bind:src="dragElementIndicator"
                style="width: 20px; height: 32px; margin-top: 39px; margin-right: 32px; cursor: grab;">
              <!-- <i class="bi bi-grip-vertical handle d-block" style="font-size: 20px;"></i> -->
              <div style="width: 110px; height: 110px; overflow: hidden; margin-left: auto; margin-right: auto;"
                class="position-relative element-preview d-none d-md-block">
                <img class="h-100 position-absolute top-0 start-0" v-bind:src="this.publicPath + element['image_url']"
                  style="margin-left: -32px">
              </div>
              <div class="reorder-text">
                <div class="d-none d-sm-block">
                  <b>{{ element['name'] }}</b>
                  <br>
                </div>
                {{ element['type'] }}
              </div>
              <img class="trash-icon" v-bind:src="trashIcon" v-on:click="this.eraseFlagElement(index)">
              <i class="fa fa-times close" @click="removeAt(index)"></i>
            </li>
          </template>
        </draggable>
      </div>

      <div class="w-100 mt-3" style="text-align: right">
        <div>
          <button class="btn btn-primary connexion-button white-button" style="margin-right: 16px; font-weight: 600;"
            v-on:click="this.closeModal()">
            Annuler
          </button>
          <button class="btn btn-primary connexion-button yellow-button" style="font-weight: 600; color: #c8ebfb"
            v-on:click="this.saveReorder()">
            Sauver
          </button>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import draggable from 'vuedraggable'


export default {
  name: "ReorderFlagElementsModal",
  components: { VueFinalModal, draggable },
  data() {
    return {
      publicPath: process.env.BASE_URL + 'flag-elements-img/',
      enabled: true,
      drag: false,
      flagElements: [],
      list: [
        { name: "John", id: 0 },
        { name: "Joao", id: 1 },
        { name: "Jean", id: 2 }
      ],
      emptyflagElements: {},
      dragElementIndicator: require('../../assets/drag-element-indicator.png'),
      trashIcon: require('../../assets/trash-icon.png'),
    }
  },
  props: {
    closeModal: {
      type: Function
    }
  },
  mounted() {
    // console.log('mounted')
    this.loadCurrentFlagElements()
    // console.log(this.$store.state.chosenFlagElements)
  },
  methods: {
    eraseFlagElement(index) {
      // if (confirm("Êtes-vous sûr de vouloir supprimer cet élément ?") == true) {
      this.flagElements.splice(index, 1)
      // }
    },
    loadCurrentFlagElements() {
      this.flagElements = []
      var chosenFlagType
      for (chosenFlagType in this.$store.state.chosenFlagElements) {
        // console.log(chosenFlagType)
        if (this.$store.state.chosenFlagElements[chosenFlagType] != '' && this.$store.state.chosenFlagElements[chosenFlagType]['image_url'])
          this.flagElements.unshift(this.$store.state.chosenFlagElements[chosenFlagType])
      }
      // for (chosenFlagType in this.$store.state.chosenFlagElements) {
      //   console.log(chosenFlagType)
      //   if (this.$store.state.chosenFlagElements[chosenFlagType] != '')
      //     this.flagElements.push(this.$store.state.chosenFlagElements[chosenFlagType])
      // }

    },
    saveReorder() {
      var newChosenFlagElements = {}
      var type = ""

      for (let index = this.flagElements.length - 1; index >= 0; index--) {
        console.log(this.flagElements[index])
        type = this.flagElements[index]['type']
        newChosenFlagElements[type] = this.flagElements[index]
      }
      this.$store.state.chosenFlagElements = newChosenFlagElements
      this.closeModal()
      // for(var index in this.flagElements) {
      //   console.log(flagElement)
      // }
    }
  },
}
</script>

<style scopped>
.reorder-text {
  margin-left: 16px;
  margin-top: 30px;
  flex-grow: 2;
  min-width: 190px;
}

.trash-icon {
  width: 20px;
  height: 25px;
  margin-top: 39px;
  margin-left: auto;
  cursor: pointer
}

.ghost {
  opacity: 0.5;
  background: rgb(230, 173, 57);
}

.not-draggable {
  cursor: no-drop;
}

.element-preview {
  border: 2px solid #A9A9A9;
  border-radius: 14px;
}

@media screen and (max-width: 575px) {
  .reorder-text {
    margin-top: 42px;
    margin-left: 0;
    min-width: unset;
  }
}
</style>
