<template>
  <div class="credit-layout page-layout">
    <div class="credit-layout-container page-layout-container">
      <div class="d-block d-lg-block">
        <h2 class="title-police">Mes crédits
        </h2>
        <CreditBanner />
        <span v-if="this.show">
          <table class="invoice-list" v-if="list && list.length > 0">
            <tr>
              <th>Action</th>
              <th>Nombre de crédits</th>
              <th>Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            <!-- Affichez les éléments de la liste ici -->
            <tr v-for="item in list" :key="item.id">
              <td>
                <span v-if="item.type == 'credit'">Achat de crédits</span>
              </td>
              <td>{{ item.nb_credits }} crédit<span v-if="item.nb_credits > 1">s</span></td>
              <td>{{ formattedDate(item.date) }}</td>
              <td>
                <span v-if="item.type == 'credit'">Réglé</span>
              </td>
              <td>
                <span v-if="item.type == 'credit'">
                  <span v-if="item.info">
                    <a :href="getInvoiceUrl(item.info)" target="_blank">Facture</a>
                  </span>

                  <span v-else>
                    En traitement
                  </span>
                </span>
              </td>
            </tr>
          </table>
          <div v-else>
            <!-- Si la liste est vide, affichez le composant CreditBuy -->
            <CreditBuy></CreditBuy>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import CreditBuy from './CreditBuy.vue';
import CreditBanner from '../profile/CreditBanner.vue'
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

export default {
  name: "CreditLayout",
  components: { CreditBuy, CreditBanner },
  data() {
    return {
      show: false,
      list: {}
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    this.getList()
  },
  computed: {
    credits() {
      return this.$store.getters.credits;
    },
  },
  watch: {
    credits() {
      setTimeout(() => {
        this.getList()
      }, 3000);
    },
  },
  methods: {
    async getList() {
      let items = await this.$store.state.fetch.get('/user/credits-history')
      this.list = items;
      this.show = true;
    },
    formattedDate(timestamp) {
      const date = parseISO(timestamp);
      return format(date, "dd MMMM yyyy à HH:mm:ss", { locale: fr });
    },
    getInvoiceUrl(info) {
      let data = JSON.parse(info);
      console.log(data)
      return data.hosted_invoice_url;
    }
  }
};
</script>

<style scoped>
option:hover {
  background-color: yellow;
}
</style>