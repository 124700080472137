<template>
  <div class="save-flag">
    <canvas id="finalDrawing" hidden></canvas>
    <canvas id="finalDrawingHD" hidden></canvas>

    <div class="save-flag-container">
      <h1 class="title-police">Mon drapeau</h1>
      <CreditBanner />
      <div class="d-flex">
        <div style="flex-grow: 1">
          <!-- <div class="flag-name-container">
            <h3 class="mt-0 save-subtitle">Nomme ton drapeau</h3>
            <input type="text" class="form-control mb-3" placeholder="Nom du drapeau" aria-label="flag-name"
              v-model="flagName" />
            <button style="margin-right: 0" class="connexion-button yellow-button w-100" v-on:click="this.saveFlag">
              Sauvegarder mon drapeau
            </button>
          </div> -->
          <div class="save-section mb-5">
            <!-- <h3 class="save-subtitle">Partager mon drapeau</h3> -->
            <!-- <span v-for="(option, index) in this.$store.state.chosenFlagElements" v-bind:key="'option-' + index"> -->
            <!-- {{ option }} -->
            <!-- <FlagElementPreview v-bind:imageUrl="[option['image_url']]">
            </FlagElementPreview> -->

            <!-- </span> -->
            <h3 class="save-subtitle">Télécharger mon drapeau</h3>
            <p>
              Le téléchargement en qualité standard te permettra d'utiliser ton drapeau comme avatar sur les réseaux
              sociaux ou en fond d’écran téléphone. Le téléchargement en qualité premium te permettra d'avoir une image à
              imprimer sur le support physique de ton choix, comme un t-shirt, un mug, ou un drapeau 🤩
              <br>
              <br>
              Pour les drapeaux qui ne contiennent que des éléments gratuits, la watermark sous la tête de mort ne
              disparaît pas après le téléchargement qualité standard. Pour enlever la watermark tu peux opter pour le
              téléchargement qualité premium pour un crédit.
              <br>
              <br>
              Si ton drapeau comporte des éléments payants, la watermark sur la tête de mort disparaîtra complètement
              lors du téléchargement, à condition que tu aies suffisamment de crédits.
            </p>
            <!-- <button class="connexion-button yellow-button mb-2">
              Téléchargement gratuit
            </button>
            <br />
            <button class="connexion-button yellow-button">
              Téléchargement HQ - 9.99€
            </button> -->
            <br />
            <button class="connexion-button yellow-button mb-2" v-on:click="lowQualityDownload"
              style="padding-right: 8px !important;">
              <!-- <img :src="telechargerPlein" style="width: 25px; margin-right: 5px">
              <img :src="telecharger" style="width: 25px; margin-right: 5px"> -->
              Téléchargement qualité standard = {{ displayPrice(this.mediumQualityPrice) }}
              <!-- <img :src="gratuit" style="width: 25px; margin-right: 5px">
              <img :src="gratuitNoir" style="width: 25px; margin-right: 5px"> -->
              <!-- <img :src="gratuitBleu" style="margin-left: 5px; width: 25px; margin-right: 5px"> -->
            </button>
            <div style="font-weight: 700; margin-bottom: 20px;">
              800 par 800 pixels
              <br>
              Adaptée pour le partage sur les réseaux sociaux ou comme fond d'écran de téléphone.
            </div>
            <button class="connexion-button yellow-button mb-2" v-on:click="highQualityDownload">
              Téléchargement Qualité Premium sans watermark = {{ displayPrice(this.mediumQualityPrice + 1) }}
            </button>
            <div style="font-weight: 700; margin-bottom: 20px;">
              3200 par 3200 pixels
              <br>
              Résolution plus grande. Plus adapté pour des fonds d'écrans PC et l'impression sur des supports physiques.
            </div>
          </div>
          <div class="d-none">
            <h3>Imprimer mon drapeau</h3>
          </div>
        </div>
        <div>
          <div id="drawing" style="flex-grow: 1; overflow: hidden">
            <!-- <img v-bind:src="this.publicPath + '_basic_flag.png'"> -->
            <!-- <div v-for="(index, content, chosenFlagElementType) in this.$store.state.chosenFlagElements"
                v-bind:key="'test-' + index">
                {{ index }}
                {{ content }}
                {{ chosenFlagElementType }}
              </div> -->
            <!-- <img v-bind:src="this.watermarkPath" v-if="this.addWatermark" /> -->
            <span v-for="(chosenFlagElement, chosenFlagElementType, index) in this.$store.state.chosenFlagElements"
              v-bind:key="'drawing-' + index">
              <img v-if="chosenFlagElement !== '' && chosenFlagElement['image_url']
              " v-bind:src="this.publicPath + chosenFlagElement['image_url']" />
              <img v-if="chosenFlagElementType == '01 DRAPEAUX - FLAGS'" class="h-100 position-absolute top-0 start-0"
                v-bind:src="this.publicPath + '../logo watermark/watermark 25.png'">
            </span>
            <img v-bind:src="this.watermarkPath" class="h-100 position-absolute top-0 start-0"
              v-if="$store.getters.containsPremiumElements" />

            <!-- <img v -if=" this.$store.state.chosenFlagElements['cross-back'] !==''"
                v-bind:src="this.publicPath + this.$store.state.chosenFlagElements['cross-back']['image_url']">
              <img v-if="this.$store.state.chosenFlagElements['upper-skull'] !== ''"
                v-bind:src="this.publicPath + this.$store.state.chosenFlagElements['upper-skull']['image_url']">
              <img v-if="this.$store.state.chosenFlagElements['jaw'] !== ''"
                v-bind:src="this.publicPath + this.$store.state.chosenFlagElements['jaw']['image_url']">
              <img v-if="this.$store.state.chosenFlagElements['eyes'] !== ''"
                v-bind:src="this.publicPath + this.$store.state.chosenFlagElements['eyes']['image_url']"> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CreditBanner from '../profile/CreditBanner.vue'
import { mapGetters } from 'vuex';

export default {
  name: "SaveFlag",
  components: { CreditBanner },
  data() {
    return {
      watermark: process.env.BASE_URL + 'logo watermark/watermark.png',
      gratuit: require('../../assets/gratuit.png'),
      gratuitBleu: require('../../assets/free bleu.png'),
      gratuitNoir: require('../../assets/free noir.png'),
      telechargerPlein: require('../../assets/download (1).png'),
      telecharger: require('../../assets/download.png'),
      publicPath: process.env.BASE_URL + "flag-elements-img/",
      watermarkPath: process.env.BASE_URL + 'logo watermark/watermark 25.png',
      flagName: "",
      canvas: null,
      ctx: null,
      canvasHD: null,
      ctxHD: null,
      addWatermark: false,
    };
  },
  computed: {
    mediumQualityPrice() {
      return (this.$store.getters.containsPremiumElements ? 1 : 0)
    },
    ...mapGetters(['credits'])
  },
  mounted() {
    this.flagName = this.generateFlagName(15);

    const canvas = document.querySelector("#finalDrawing");
    canvas.width = 800;
    canvas.height = 800;
    const ctx = canvas.getContext("2d");
    this.ctx = ctx;
    this.canvas = canvas;

    const canvasHD = document.querySelector("#finalDrawingHD");
    canvasHD.width = 3200;
    canvasHD.height = 3200;
    const ctxHD = canvasHD.getContext("2d");
    this.ctxHD = ctxHD;
    this.canvasHD = canvasHD;
  },
  methods: {
    generateFlagName(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
    displayPrice(cost) {
      return (cost == 0 ? "Gratuit" : `${cost} crédit${cost > 1 ? 's' : ''}`)
    },
    useCredits(creditsPrice) {
      if (creditsPrice == 0) {
        alert("Merci d'avoir choisi WeArePirates ! ")
        if (window.confirm('Aidez-nous en donnant votre avis sur WeArePirates !'))
          window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdciFqf2xp1vj1OZpRz_qsmLI7rh8_1Yu-D8YuSP02aOq8rlg/viewform';
        return true
      }
      if (this.credits < creditsPrice) {
        alert("Vous n'avez pas assez de crédits pour acheter ce drapeau avec cette qualité.")
        return false
      }
      if (!window.confirm("Es-tu sûr de dépenser " + creditsPrice + " credit(s) pour télécharger ce drapeau ?")) {
        return false
      }
      const params = {
        user_unique_code: this.$store.state.connectedUserUniqueCode,
        number_of_credits: creditsPrice,
      };
      console.log(params)
      axios
        .post('https://we-are-pirates.herokuapp.com' + "/admin/use_credits", params)
        .then((response) => {
          console.log(response)
        })
      alert("Merci d'avoir choisi WeArePirates !")
      return true
    },
    // freeDownload() {
    //   if (!this.useCredits(0))
    //     return
    //   this.downloadFinalImage(800, 500, false, !this.$store.state.chosenFlagElements)
    // },
    lowQualityDownload() {
      if (this.$store.state.connectedUserUniqueCode == null) {
        this.$store.state.displaySignInModal = true;
        return;
      }
      if (!this.useCredits(this.mediumQualityPrice))
        return
      this.downloadFinalImage(800, 800, false, !this.$store.getters.containsPremiumElements)
      this.saveFlag(false)
    },
    highQualityDownload() {
      if (this.$store.state.connectedUserUniqueCode == null) {
        this.$store.state.displaySignInModal = true;
        return;
      }
      if (!this.useCredits(this.mediumQualityPrice + 1))
        return
      this.downloadFinalImage(3200, 3200, true)
      this.saveFlag(true)
    },
    openPremiumCheckoutModal() {
      this.$store.state.displayCreditModal = true;
    },
    saveFlag(highQuality = false) {
      const flagInfos = {
        unique_user_code: this.$store.state.connectedUserUniqueCode,
        flag_name: this.flagName,
        flag_elements: JSON.stringify(this.$store.state.chosenFlagElements),
        premium_version_payed: (highQuality ? 1 : 0),
        contains_premium_elements: (this.$store.getters.containsPremiumElements ? 1 : 0),
      };
      axios
        .post('https://we-are-pirates.herokuapp.com' + "/admin/save_flag", flagInfos)
        .then((response) => {
          // response["data"]
          console.log("create");
          console.log(response["data"]);
          if (response["data"] == "flag_name") {
            if (
              confirm(
                'Le drapeau "' +
                this.flagName +
                "\" existe déjà, êtes vous sûr de l'écraser ?"
              ) == true
            ) {
              axios
                .post('https://we-are-pirates.herokuapp.com' + "/admin/update_flag", flagInfos)
                .then(() => {
                  // response["data"]
                  console.log("done update");
                });
            } else alert("Drapeau non sauvegardé");
          } else alert('Drapeau "' + this.flagName + '" sauvegardé !');
          // location.reload()
        });
    },
    downloadFinalImage(width = 3200, height = 3200, HD = false, watermark = false) {
      if (this.$store.state.connectedUserUniqueCode == null) {
        this.$store.state.displaySignInModal = true;
        return;
      }
      if (!this.flagName) {
        alert("Un nom de drapeau est nécessaire !");
        return;
      }
      this.drawFinalImage(width, height, HD, watermark);
      var image = HD ? this.canvasHD
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream") :
        this.canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
      var link = document.createElement("a");
      link.download = this.flagName + ".png";
      link.href = image;
      link.click();
    },
    drawFinalImage(width, height, HD, watermark) {
      const context = HD ? this.canvas.getContext("2d") : this.canvasHD.getContext("2d");

      context.clearRect(0, 0, width, height);
      for (var singleElement in this.$store.state.chosenFlagElements) {
        if (this.$store.state.chosenFlagElements[singleElement]["image_url"]) {
          this.drawSingleImage(
            this.publicPath + this.$store.state.chosenFlagElements[singleElement]["image_url"],
            width,
            height,
            HD,
            singleElement === '01 DRAPEAUX - FLAGS' ? 0 : (HD ? 600 : 150)
          );
          if (watermark && singleElement === '01 DRAPEAUX - FLAGS') {
            // console.log(process.env.BASE_URL + 'logo watermark/watermark download.png')
            this.drawSingleImage(
              this.watermark,
              width,
              height,
              HD,
              0
            );
          }
        }
      }
      // console.log(this.$store.getters.containsPremiumElements)
      if (this.$store.getters.containsPremiumElements && watermark) {
        console.log('toto')
        this.drawSingleImage(
          this.watermark,
          width,
          height,
          HD
        );
      }
      // this.$store.state.chosenFlagElements.forEach(singleElement => {
      //   this.drawSingleImage(singleElement)
      //   console.log(singleElement)
      // });
      // this.canvas.toBlob(
      //   (blob) => {
      //     if (blob) {
      //       // showing the compressed image
      //       resizedImage.src = URL.createObjectURL(resizedImageBlob);
      //     }
      //   },
      //   "image/jpeg",
      //   0.5
      // );
    },
    drawSingleImage(src, width, height, HD, fullsizeDifference = 0) {
      var singleImage = new Image();
      singleImage.src = src;
      HD ? this.ctxHD.drawImage(singleImage, 0, fullsizeDifference, width, fullsizeDifference ? height - fullsizeDifference * 2 : height) : this.ctx.drawImage(singleImage, 0, fullsizeDifference, width, fullsizeDifference ? height - fullsizeDifference * 2 : height)
    },
  },
};
</script>

<style scoped>
h3 {
  font-weight: 600;
  margin-top: 24px;
}

.save-subtitle {
  font-size: 20px;
  margin-bottom: 15px;
}

.save-flag {
  margin: 56px;
}

.save-flag-container {
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  max-width: 1616px;
  /* margin: 56px; */
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

.flag-name-container {
  width: 320px;
}

.flag-name-container input {
  height: 45px;
}

#drawing {
  position: relative;
  min-width: 800px;
  max-width: 50%;
  /* width: 3200px !important; */
  height: 500px;
  /* border: solid 1px black; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: auto;
  margin-right: 0;
  border-radius: 15px;
  /* background-color: black; */
}

#drawing img {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  border-radius: 15px;
}

@media screen and (max-width: 991px) {
  #drawing {
    display: none;
  }

  .flag-name-container {
    width: inherit;
  }
}

@media screen and (max-width: 1350px) {
  #drawing {
    min-width: 500px;
    /* max-width: 50%; */
    /* width: 3200px !important; */
    height: 312px;
    /* border: solid 1px black; */
  }

  #drawing img {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: 15px;
  }
}
</style>