<template>
  <div class="h-100">
    <ReorderFlagElementsModal v-model="displayReorderFlagElementModal"
      v-model:closeModal="this.closeReorderFlagElementsModal" v-bind:key="'reorder-' + this.reorderIndex">
    </ReorderFlagElementsModal>
    <div class="d-flex flex-column main-page-container h-100">
      <PreviewCompletedFlag></PreviewCompletedFlag>
      <div class="flag-creator">
        <div class="flag-creator-container">
          <!-- <h1 class="title">
            MyFlag
          </h1> -->

          <div class="category-select">
            <h3 class="fw-bold mt-0 mb-3">
              Choix Catégorie
            </h3>
            <div class="selectable-categories-container">
              <div style="width: max-content; margin-left: -6px; margin-right: -12px;">
                <span v-for="(category, index) in categories" v-bind:key="'select-category-' + index">
                  <span v-if="category['preview']">
                    <FlagElementPreview v-bind:imageUrl="category['preview']"
                      v-bind:isChosen="this.isCategoryChosen(index)" v-bind:zoom="0"
                      @selected="this.selectedCategory = index; this.setSubcategory('')" :pathFromRoot="true"
                      :isShowFreeIcon="false">

                    </FlagElementPreview>
                  </span>
                </span>
                <!-- <div class="selectable-category" v-for="(category, index) in categories"
                  v-bind:key="'select-category-' + index" v-bind:class="this.categoryChosenClass(index)"
                  v-on:click="this.selectedCategory = index; this.setSubcategory('')">

                  <div class="position-absolute">
                    <img v-for="(previewImg, index) in category['preview']" class="option-img"
                      v-bind:key="'preview-img-' + index" v-bind:src="this.publicPath + previewImg">
                  </div>

                </div> -->
              </div>
            </div>
          </div>

          <div class="element-select" v-for="(category, index) in categories" v-bind:key="'select-options-' + index"
            v-bind:class="this.displayCorrectOptionsClass(index)">
            <h3 class="fw-bold mb-3">
              {{ category.choiceText }}
            </h3>
          </div>

          <div class="d-flex" style=" margin-left: -6px; margin-right: -12px">
            <div class="position-relative" style="flex-grow: 1">
              <div id="options-selector" class="options-container">
                <span v-if="this.categories[this.selectedCategory]['categoryWithFolders'] != null">
                  <CategoryWithFolders
                    v-bind:flagElementsOptions="this.categoryFoldersOptions(this.categories[this.selectedCategory]['categoryWithFolders'])"
                    v-bind:folderDisplayAllElements="this.categories[this.selectedCategory].folderDisplayAllElements"
                    :sub-category="this.subCategory" @set-subcategory="this.setSubcategory"
                    v-bind:zoom="this.categories[this.selectedCategory]['zoom']"></CategoryWithFolders>
                  <!-- <div v-for="(option, index) in this.categoryFoldersOptions(this.categories[this.selectedCategory]['categoryWithFolders'])" class="selectable-option position-relative"
                    v-bind:key="'option-' + index" v-on:click="element_chosen(option['type'], option)"
                    v-bind:class="this.selectedOptionClass(option['id'], option['type'])">
                    <div class="position-absolute">
                      <img class="option-img" v-bind:src="this.publicPath + option['image_url']">
                    </div>
                  </div> -->
                </span>
                <span v-else>
                  <!-- {{  this.$store.state.chosenFlagElements["06 CRÂNES - SKULLS"] }} -->
                  <span v-for="(option, index) in this.currentProposedOptions" v-bind:key="'option-' + index">
                    <FlagElementPreview
                      v-bind:imageUrl="[option['image_url'] == '01 DRAPEAUX - FLAGS/empty.svg' ? '01 DRAPEAUX - FLAGS/tata.jpg' : option['image_url']]"
                      v-bind:isChosen="this.isOptionChosen(option['id'], option['type'])"
                      v-bind:zoom="this.categories[this.selectedCategory]['zoom']"
                      @selected="this.element_chosen(option['type'], option)" v-bind:isPremium="option['premium'] == 1"
                      v-bind:isShowFreeIcon="true">

                    </FlagElementPreview>
                  </span>
                  <!-- <div v-for="(option, index) in this.currentProposedOptions" class="selectable-option position-relative"
                    v-bind:key="'option-' + index" v-on:click="element_chosen(option['type'], option)"
                    v-bind:class="this.selectedOptionClass(option['id'], option['type'])">

                    <div class="position-absolute">
                      <img class="option-img" v-bind:src="this.publicPath + option['image_url']">
                    </div>
                  </div> -->
                </span>

              </div>
              <!-- <div v-if="showTopShaddow" class="shadow shadow-top" />
              <div v-if="showBottomShaddow" class="shadow shadow-bottom" /> -->
            </div>
            <div id='drawing' v-bind:class="this.flagClass">
              <!-- <img v-bind:src="this.publicPath + '_basic_flag.png'"> -->
              <!-- <div v-for="(index, content, chosenFlagElementType) in this.$store.state.chosenFlagElements"
                v-bind:key="'test-' + index">
                {{ index }}
                {{ content }}
                {{ chosenFlagElementType }}
              </div> -->
              <span v-for="(chosenFlagElement, chosenFlagElementType, index) in this.$store.getters.flagWithWatermark"
                v-bind:key="'drawing-' + index">
                <img v-if="chosenFlagElement && chosenFlagElement['image_url']"
                  v-bind:src="this.publicPath + chosenFlagElement['image_url']">
              </span>
              <img :src="watermarkPath" v-if="shouldDisplayWatermark">

              <!-- <img v -if=" this.$store.state.chosenFlagElements['cross-back'] !==''"
                v-bind:src="this.publicPath + this.$store.state.chosenFlagElements['cross-back']['image_url']">
              <img v-if="this.$store.state.chosenFlagElements['upper-skull'] !== ''"
                v-bind:src="this.publicPath + this.$store.state.chosenFlagElements['upper-skull']['image_url']">
              <img v-if="this.$store.state.chosenFlagElements['jaw'] !== ''"
                v-bind:src="this.publicPath + this.$store.state.chosenFlagElements['jaw']['image_url']">
              <img v-if="this.$store.state.chosenFlagElements['eyes'] !== ''"
                v-bind:src="this.publicPath + this.$store.state.chosenFlagElements['eyes']['image_url']"> -->
            </div>
          </div>
        </div>
      </div>
      <div class="footer-section">
        <div class="footer-container">
          <button class="d-none d-lg-inline-block"
            v-on:click="this.reorderIndex++; this.displayReorderFlagElementModal = true">
            Réorganiser les éléments
          </button>
          <button class="d-inline-block d-lg-none"
            v-on:click="this.reorderIndex++; this.displayReorderFlagElementModal = true">
            Réorganiser
          </button>
          <!-- <button class="d-none d-lg-inline-block" v-on:click="this.generateRandomFlag">
            Générer aléatoirement
          </button> -->
          <button class="d-none d-lg-inline-block" v-on:click="this.$store.commit('resetFlag')">
            Effacer
          </button>
          <!-- <button class="d-inline-block d-lg-none" v-on:click="this.generateRandomFlag">
            Aléatoire
          </button> -->
          <button class="d-inline-block d-lg-none" v-on:click="this.$store.commit('resetFlag')">
            Effacer
          </button>
          <button style="margin-right: 0;" class="connexion-button yellow-button" v-on:click="this.saveFlag()">
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ReorderFlagElementsModal from '../modals/ReorderFlagElementsModal.vue';
import CategoryWithFolders from './CategoryWithFolders.vue';
import PreviewCompletedFlag from './PreviewCompletedFlag.vue';
import FlagElementPreview from '../FlagElementPreview.vue';
import { toRaw } from 'vue';

export default {
  name: "FlagCreator",
  components: { ReorderFlagElementsModal, CategoryWithFolders, PreviewCompletedFlag, FlagElementPreview },
  data: () => ({
    watermarkPath: process.env.BASE_URL + 'logo watermark/watermark 25.png',
    displayReorderFlagElementModal: false,
    reorderIndex: 0,
    publicPath: process.env.BASE_URL + "flag-elements-img/",
    selectedCategory: 0,
    categories: [
      {
        categoryName: "01 DRAPEAUX - FLAGS",
        choiceText: "Choix drapeaux",
        preview: ["ICONES/ICONE FLAG.jpeg"],
        zoom: 1
      },
      {
        categoryName: "06 CRÂNES - SKULLS",
        choiceText: "Choix crânes",
        categoryWithFolders: "06 CRÂNES - SKULLS",
        preview: ["ICONES/ICONE CRANE.png"],
        zoom: 1
      },
      {
        categoryName: "04 MÂCHOIRES - JAWS",
        choiceText: "Choix mâchoires",
        categoryWithFolders: "04 MÂCHOIRES - JAWS",
        preview: ['ICONES/ICONE MACHOIRE.png'],
        zoom: 1
      },
      {
        categoryName: "13 MASQUES FACIALS - FACE MASKS",
        choiceText: "Choix masques & casques",
        categoryWithFolders: "13 MASQUES FACIALS - FACE MASKS",
        preview: ["ICONES/ICONE MASQUE.png"],
      },
      {
        categoryName: "08 ORBITES - ORBITS",
        choiceText: "Choix orbites",
        folderDisplayAllElements: true,
        categoryWithFolders: "ORBITS",
        preview: ["ICONES/ICONE ORBITES.png"],
        zoom: 3
      },
      {
        categoryName: "09 YEUX - EYES",
        choiceText: "Choix yeux",
        categoryWithFolders: "EYES",
        folderDisplayAllElements: true,
        preview: ["ICONES/ICONE YEUX.png"],
        zoom: 3
      },
      {
        categoryName: "14 LUNETTES _ MASQUES - GLASSES _ MASK",
        choiceText: "Choix lunettes",
        categoryWithFolders: "14 LUNETTES _ MASQUES - GLASSES _ MASK",
        preview: ["ICONES/ICONE LUNETTES.png"],
        zoom: 2

      },
      {
        categoryName: "10 NEZ - NOSES",
        choiceText: "Choix nez",
        categoryWithFolders: null,
        preview: ["ICONES/ICONE NEZ.png"],
        zoom: 3
      },
      {
        categoryName: "07 DENTS - TOOTH",
        choiceText: "Choix dents",
        categoryWithFolders: "07 DENTS - TOOTH",
        folderDisplayAllElements: true,
        preview: ["ICONES/ICONE DENTS.png"],
        zoom: 2
      },
      {
        categoryName: "03 CROIX - CROSS",
        choiceText: "Choix croix",
        categoryWithFolders: "03 CROIX - CROSS",
        preview: ["ICONES/ICONE CROSS.png"],
        zoom: -2
      },
      {
        categoryName: "03.5 SINGLE CROSS",
        choiceText: "Choix croix uniques",
        categoryWithFolders: "03.5 SINGLE CROSS",
        preview: ["ICONES/ICONE ONECROSS.jpeg"],
        zoom: -2
      },
      // {
      //   categoryName: "03 CROIX - CROSS",
      //   choiceText: "Choix de la croix",
      //   categoryWithFolders: "03 CROIX - CROSS",
      //   preview: ["ICONES/ICONE CROSS.png"],
      //   zoom: -2
      // },
      {
        categoryName: "05 BARBES - BEARDS",
        choiceText: "Choix barbes",
        categoryWithFolders: "05 BARBES - BEARDS",
        preview: ["ICONES/ICONE BARBE.jpeg"],
      },
      {
        categoryName: "11 MOUSTACHES - MUSTACHES",
        choiceText: "Choix moustaches",
        categoryWithFolders: "11 MOUSTACHES - MUSTACHES",
        preview: ["ICONES/ICONE MOUSTACHE.png"],
      },
      {
        categoryName: "15 CHEVEUX - HAIRS",
        choiceText: "Choix cheveux",
        categoryWithFolders: "15 CHEVEUX - HAIRS",
        preview: ["ICONES/ICONE CHEVEUX.png"],
        zoom: -1
      },
      {
        categoryName: "16 CHAPEAUX CASQUE  - HATS HELMETS",
        choiceText: "Choix chapeaux",
        categoryWithFolders: "16 CHAPEAUX CASQUE  - HATS HELMETS",
        preview: ["ICONES/ICONE CHAPEAU.png"],
        zoom: -1
      },
      // {
      //   categoryName: "13 BOTTOM",
      //   choiceText: "Choix du bottom (à renommer)",
      //   categoryWithFolders: null,
      //   // preview: ["13 BOTTOM/CROSSBACK. GOUVERNAIL NOIR.svg"]
      // },
      // {
      //   categoryName: "15 FLAGS",
      //   choiceText: "Choix du drapeau",
      //   categoryWithFolders: null
      // },
      {
        categoryName: "17 CORNES - HORNS",
        choiceText: "Choix cornes",
        folderDisplayAllElements: true,
        categoryWithFolders: "HORNS",
        preview: ["ICONES/ICONE CORNES.png"],
        zoom: -2
      },
      {
        categoryName: "12 EXTRA FACE",
        choiceText: "Choix extra face",
        categoryWithFolders: "12 EXTRA FACE",
        preview: ["ICONES/ICONE EXTRAFACE.png"],
        zoom: 3
      },
      {
        categoryName: "18 EXTRA FRONT",
        choiceText: "Choix accessoire un",
        categoryWithFolders: "18 EXTRA FRONT",
        preview: ["ICONES/ICONE EXTRAFRONT.png"],
        zoom: -1
      },
      {
        categoryName: "02 EXTRA BACK",
        choiceText: "Choix accessoire deux",
        categoryWithFolders: "02 EXTRA BACK",
        preview: ["ICONES/ICONE EXTRABACK.png"],
        zoom: -1
      },

      // {
      //   categoryName: "cross-items",
      //   choiceText: "Choix du fond",
      //   categoryWithFolders: "cross-"
      // },
    ],
    available_parts: [],
    // available_
    showTopShaddow: false,
    showBottomShaddow: true,
    subCategory: ""
  }),
  mounted() {
    this.SetUpScrollShaddows();
    axios
      .get("https://we-are-pirates.herokuapp.com/admin")
      .then((response) => {
        this.available_parts = response["data"].data;
        // console.log(this.available_parts);
        // if (this.available_parts.length > 12)
        //   this.showBottomShaddow = true
      });
    // const illustrations = require.context(
    //   "/public/flag_elements/items CROSS",
    //   true,
    //   /^.*\.png$/
    // )
    // console.log(illustrations.keys()[0])
  },
  computed: {
    toto() {
      return (this.$store.getters.containsPremiumElements)
    },
    hasPremiumSelected() {
      // if (!this.$store.state.chosenFlagElements.val)
      //   return false

      var tmp = toRaw(this.$store.state.chosenFlagElements)
      return (Object.values(tmp).findIndex(element => element.premium == '1') >= 0)
    },
    currentProposedOptions() {
      return this.proposedOptionsFromCategory(this.selectedCategory).sort((option1, option2) => {
        console.log("toto")
        console.log(option1)
        console.log(option2)
        if (option1.premium === '1' && option2.premium === '0') {
            return 1;
        } else if (option1.premium === '0' && option2.premium === '1') {
            return -1; 
        } else {
            return 0; 
        }
      });
    },
    flagCategories() {
      var result = []
      this.available_parts.forEach(flagElement => {
        if (!result.includes(flagElement['type']))
          result.push(flagElement['type'])
      })
      return (result)
    },
    shouldDisplayWatermark() {
      return (this.$store.getters.containsPremiumElements)
    },
    flagClass() {
      var tmp = toRaw(this.$store.state.chosenFlagElements)
      if (this.$store.getters.containsPremiumElements == false)
        return ''
      if (Object.values(tmp).findIndex(element => element.premium == '1') >= 0 && (!this.$store.state.connectedUser || this.$store.state.connectedUser.credits < 1))
        return "premium-item";
      return "free-item";
    }
  },
  methods: {

    setSubcategory(newSubcategory) {
      this.subCategory = newSubcategory
    },
    categoryFoldersOptions(category_group_name) {
      return (this.available_parts.filter(part => part.type.includes(category_group_name)));
    },
    saveFlag() {
      // for (var chosenFlagElement in this.$store.state.chosenFlagElements) {
      //   if (!this.$store.state.chosenFlagElements[chosenFlagElement]['image_url'])
      //     delete this.$store.state.chosenFlagElements[chosenFlagElement]
      // }
      this.$router.push('/save-flag')
    },
    closeReorderFlagElementsModal() {
      this.displayReorderFlagElementModal = false
    },
    proposedOptionsFromCategory(category) {
      return (this.available_parts.filter(part => part.type == this.categories[category]["categoryName"]));
    },
    // generateRandomFlag() {
    //   this.categories.forEach((category, index) => {
    //     if (this.categories['categoryWithFolders'])
    //       return;
    //     console.log(index + category["categoryName"]);
    //     console.log(this.proposedOptionsFromCategory(index));
    //     // console.log(this.proposedOptionsFromCategory(index).length)
    //     let randNumber = Math.trunc(Math.random() * this.proposedOptionsFromCategory(index).length);
    //     let randElement = this.proposedOptionsFromCategory(index)[randNumber];
    //     if (randElement)
    //       this.$store.state.chosenFlagElements[randElement["type"]] = randElement;
    //   });
    // },
    proposedOptionsFromCategoryAndSubcategory(category) {
      return (this.available_parts.filter(part => part.type == category));
    },
    generateRandomFlag() {
      this.flagCategories.forEach(category => {
        if (this.categories['categoryWithFolders'])
          return;
        console.log("category")
        console.log(category)
        // console.log(index)
        console.log(this.proposedOptionsFromCategoryAndSubcategory(category));
        // console.log(this.proposedOptionsFromCategory(index).length)
        let randNumber = Math.trunc(Math.random() * this.proposedOptionsFromCategoryAndSubcategory(category).length);
        let randElement = this.proposedOptionsFromCategoryAndSubcategory(category)[randNumber];
        if (randElement)
          this.$store.state.chosenFlagElements[randElement["type"]] = randElement;
      });
    },
    SetUpScrollShaddows() {
      const scroller = document.getElementById("options-selector");
      if (scroller) {
        scroller.addEventListener("scroll", e => this.setShadows(e));
      }
      // may do some error handling here
    },
    setShadows(event) {
      window.requestAnimationFrame(() => {
        // console.log(event.target.scrollBottom)
        if (event.target.scrollTop > 0) {
          this.showTopShaddow = true;
        }
        else {
          this.showTopShaddow = false;
        }
        // console.log('if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {')
        // console.log('if (' + event.target.offsetHeight + '+' + event.target.scrollTop + '>=' + event.target.scrollHeight + ') {')
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          this.showBottomShaddow = false;
        }
        else {
          this.showBottomShaddow = true;
        }
      });
    },
    element_chosen(type, element) {
      if (element.premium == 1 && !this.$store.state.popInPremium) {
        this.$store.state.popInPremium = true;
        if (this.$store.state.connectedUserUniqueCode == null || this.$store.state.connectedUser.credits == 0) {
          this.$store.state.displayCreditModal = true;
          return;
        }
      }
      //alert("choser")
      // var tmp = toRaw(this.$store.state.chosenFlagElements)
      // console.log("tmp")
      // console.log(tmp)
      // console.log(Object.values(tmp))
      if (this.$store.getters.getFlagElement(type) && this.$store.getters.getFlagElement(type).id == element.id && type != "01 DRAPEAUX - FLAGS") {
        console.log(type)
        this.$store.state.chosenFlagElements[type] = {}
        return
      }
      console.log("else")
      this.$store.state.chosenFlagElements[type] = element

      // this.$store.commit('setSelectedFlagElement', type, element_id)

      // console.log(this.$store.state.chosenFlagElements[type])

    },
    elementChosenFunction(type, element_id) {
      return function () {
        console.log("elementChosenFunction")
        if (this.$store.state.chosenFlagElements[type] == element_id) {
          console.log("deselect")
          this.$store.commit('setSelectedFlagElement', type, {})
          return
        }
        console.log("select")
        console.log("this.$store.state.chosenFlagElements[" + type + "]")
        console.log("= " + element_id['image_url'])
        this.$store.commit('setSelectedFlagElement', type, element_id)
        // this.$store.state.chosenFlagElements[type] = element_id;
        console.log(this.$store.state.chosenFlagElements[type])

      };
    },
    categoryChosenFunction(index) {
      return function () {
        console.log(index)
        this.selectedCategory = index;
        console.log(this.selectedCategory)
        this.subCategory = ''
      };
    },
    categoryChosenClass(index) {
      if (index == this.selectedCategory)
        return ("chosen");
      return ("");
    },
    isCategoryChosen(index) {
      if (index == this.selectedCategory)
        return (true);
      return (false);
    },
    displayCorrectOptionsClass(index) {
      if (index == this.selectedCategory)
        return ("d-block");
      return ("d-none");
    },
    selectedOptionClass(id, type) {
      if (this.$store.state.chosenFlagElements[type] && id == this.$store.state.chosenFlagElements[type]["id"])
        return ("chosen");
      return ("");
    },
    isOptionChosen(id, type) {
      if (this.$store.state.chosenFlagElements[type] && id == this.$store.state.chosenFlagElements[type]["id"])
        return (true);
      return (false);
    }
  }
};
</script>

<style scoped>
.shadow {
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: all 0.2s ease-out;
  box-shadow: none !important;
}

.shadow-top {
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(180deg, rgba(230, 173, 57, 0.4) 0%, rgba(230, 173, 57, 0) 56px);
}

.shadow-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;

  background: linear-gradient(0deg, rgba(230, 173, 57, 0.4) 0%, rgba(230, 173, 57, 0) 56px);

  /* background: linear-gradient(180deg, rgba(230, 173, 57, 0) 0, rgba(230, 173, 57, 0.4) -56px); */
  width: 100%;
}

h3 {
  margin: 30px 0;
}

.flag-creator {
  padding: 30px 50px;
  overflow-y: auto;
  flex-grow: 2;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.flag-creator::-webkit-scrollbar {
  width: 0 !important
}

.flag-creator-container {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  max-width: 1616px;
  /* margin: 56px; */
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

.title {
  font-family: 'Cinzel Decorative';
  font-size: 45px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.selectable-categories-container {
  overflow-x: scroll;
  /* overflow: hidden; */
  /* Hide scrollbars */
  /* white-space: nowrap; */

}

.selectable-category {
  width: 100px;
  height: 100px;
  position: relative;
  background-color: white;
  display: inline-block;
  border-radius: 14px;
  margin-left: 9px;
  margin-right: 9px;
  overflow: hidden;
}

.selectable-option {
  width: 109px;
  height: 109px;
  background-color: white;
  display: inline-block;
  border-radius: 14px;
  margin: 10px 8px;
  overflow: hidden;
}

.chosen {
  border: 6px solid #DDBD3C;
  box-shadow: 0px 4px 4px 0px #00000040;

  /* padding-left: -10px */
}

.option-img {
  width: 177px !important;
  height: 110px;
  left: -34px;
  width: auto;
  position: absolute;
}

.selectable-category .option-img {
  left: -38px;
  top: -5px;
}

.chosen .option-img {
  left: -40px;
  top: -6px;
}

.selectable-category.chosen .option-img {
  left: -44px;
  top: -11px;
}

#drawing {
  position: relative;
  min-width: 800px;
  /* max-width: 50%; */
  /* width: 800px !important; */
  height: 500px;
  /* border: solid 1px black; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: auto;
  margin-right: 0;
  border-radius: 15px;
  /* background-color: black; */
}

#drawing img {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}

.footer-section {
  /* height: 65px; */
  background-color: #1B1B1B;
  /* position: fixed; */
  bottom: 0;
  width: 100%;
}

.options-container {
  flex-grow: 1;
  max-height: 500px;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  position: relative;
}

.options-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.footer-section button {
  color: white;
  background-color: #1B1B1B;
  border: 1px solid white;
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 18px;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.footer-container {
  max-width: 1616px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 991px) {
  .flag-creator {
    padding: 0;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .flag-creator-container {
    background: none;
    padding: 15px;
    padding-bottom: 0;
  }

  #drawing {
    display: none;
  }

  .footer-section {
    background: none;
    text-align: center;
  }

  .selectable-category {
    width: 76px;
    height: 76px;
  }

  .footer-section button {
    font-size: 14px;
  }

  .selectable-option {
    width: 76px;
    height: 76px;
  }

  .option-img {
    left: -50px !important;
    top: -17px !important
  }

  .chosen .option-img {
    left: -56px !important;
    top: -23px !important;
  }
}

@media screen and (max-width: 1350px) {
  #drawing {
    min-width: 500px;
    /* max-width: 50%; */
    /* width: 800px !important; */
    height: 312px;
    /* border: solid 1px black; */
  }

  #drawing img {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: 15px;
  }
}
</style>