import Vuex from 'vuex';
import user from './user.js';
import modals from './modals.js';
import Fetch from '../utils/fetch.js';

import {emptyFlag} from "../constants/emptyFlag.js"

//init store
const store = new Vuex.Store({
    modules: {
        user,
        modals
      },
      state() {
        return {
          // connectedUserId: null,
          containsPremiumElement: false,
          loadedFlagName: "toto",
          chosenFlagElements: {...emptyFlag},
          // chosenFlagElements: {
          //   "cross-back": ""
          //   "upper-skull": "",
          //   "jaw": "",
          //   "eyes": "",
          // },
          connectedUserUniqueCode: null,
          fetch: new Fetch()
        }
      },
      mutations: {
        resetFlag(state){
          state.chosenFlagElements = {...emptyFlag}
        },
        // setConnectedUserId(state, newUserId) {
        //   console.log(newUserId)
        //   state.connectedUserId = newUserId
        // },
        setSelectedFlagElement(state, type, element) {
          console.log("setSelectedFlagElement")
          console.log(type)
          console.log(element)
          state.chosenFlagElements[type] = element

          console.log("premium ?")
          console.log(state.chosenFlagElements.values.findIndex(element => element.premium == '1'))
          
        },
        resetCurrentFlag(state) {
          state.chosenFlagElements = {
            "cross-back": "",
            "upper-skull": "",
            "jaw": "",
            "eyes": "",
          }
        },
        loadSavedFlag(state, savedFlag) {
          state.chosenFlagElements = savedFlag
        }
      },
      getters: {
        flagWithWatermark (state, flagElements= null) {
          var res = {}
          var i = 0
          flagElements = null
          for (let key in (flagElements || state.chosenFlagElements)) {
            res[key] = (flagElements || state.chosenFlagElements)[key]
            if (i == 0)
              res['watermark'] = {"image_url": "../logo watermark/watermark 25.png" }
              // res['watermark'] = "watermark 25.png"
            i++
          }
          return res
        },
        getFlagElement: (state) => (type) => {
          return state.chosenFlagElements[type]
        },
        containsPremiumElements (state) {
          console.log(Object.values(state.chosenFlagElements).map(element => element && element['premium']))
          console.log(Object.values(state.chosenFlagElements).map(element => element && element['premium']).includes('1'))
          return (Object.values(state.chosenFlagElements).map(element => element && element['premium']).includes('1'))
        }
      }
})

export default store