<template>
  <div id="credit-view">
    <CreditLayout />
  </div>
</template>
  
<script>
// @ is an alias to /src
import CreditLayout from "@/components/profile/CreditLayout.vue";

export default {
  name: "CreditView",
  components: {
    CreditLayout
  },
};
</script>
  