<template>
  <div class="flag-layout page-layout">
    <div class="flag-layout-container page-layout-container">
      <div class="d-none d-lg-block">
        <h2 class="title-police">Découvre le drpaeu de mon équipage !</h2>
        <canvas id="finalDrawing"></canvas>
        <ShareNetwork
          network="twitter"
          url={{imageDataURL}}
          title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
          description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
          quote="The hot reload is so fast it\'s near instant. - Evan You"
          hashtags="vuejs,vite"
        >
          Share on Facebook
      </ShareNetwork>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '../../utils/utils.js';
export default {
  name: "FlagLayout",
  data() {
    return {
      imageDataURL: ''
    }
  },
  async mounted() {
    const canvas = document.querySelector("#finalDrawing");
            canvas.width = 800;
            canvas.height = 500;
            const ctx = canvas.getContext("2d");
            this.ctx = ctx;
            this.canvas = canvas;
    const response = await this.$store.state.fetch.get("/user/flag/" + this.$route.params.id)
    console.log("response", response)
    Utils.drawFinalImage(response.flag_elements, this.canvas);
    this.imageDataURL = canvas.toDataURL('image/png'); 
  },
  methods: {
  }
};
</script>