<template>
  <div class="flag-preview position-relative h-100">
    <!-- {{ this.flagElements }} -->
    <span v-for="(index, flagElementType) in flagElements" v-bind:key="'flag-element-' + index">
      <img class="h-100 position-absolute top-0 start-0"
        v-if="this.flagElements[flagElementType] !== '' && this.flagElements[flagElementType]['image_url']"
        v-bind:src="this.publicPath + this.flagElements[flagElementType]['image_url']">
      <!-- {{ flagElementType ==  '01 DRAPEAUX - FLAGS'  }} -->
      <img
        v-if="watermarkBehind && flagElementType ==  '01 DRAPEAUX - FLAGS'"
        class="h-100 position-absolute top-0 start-0"
        v-bind:src="this.publicPath + '../logo watermark/watermark 25.png'">
    </span>
    <img
      v-if="watermark"
      class="h-100 position-absolute top-0 start-0"
      v-bind:src="this.publicPath + '../logo watermark/watermark 25.png'">
  </div>
</template>

<script>

export default {
  name: "FlagPreview",
  data: () => ({
    publicPath: process.env.BASE_URL + 'flag-elements-img/',
  }),
  props: {
    flagElements: {
      type: Object,
      required: true
    },
    watermark: {
      type: Boolean,
      default: true
    },
    watermarkBehind: {
      type: Boolean,
      default: false
    },
  }
}

</script>

<style></style>