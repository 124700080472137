<template>
  <div class="category-with-folders">
    <!-- {{ this.getFoldersName }} -->
    <div v-if="this.subCategory == ''">
      <!-- <div v-for="(option, index) in this.flagElementsOptions" class="selectable-option position-relative"
        v-bind:key="'option-' + index" v-on:click="element_chosen(option['type'], option)"
        v-bind:class="this.selectedOptionClass(option['id'], option['type'])">
        <div class="position-absolute">
          <img class="option-img" v-bind:src="this.publicPath + option['image_url']">
        </div>
        <span class="position-absolute">
          {{ option['image_url'].split("\\")[1] }}
        </span>
      </div> -->
      <span v-for="(folderName, index) in this.getFoldersName" v-bind:key="'folder-' + index">
        <FlagElementPreview v-bind:imageUrl="this.folderImageUrl(folderName)" v-bind:zoom="this.zoom"
          @selected="$emit('setSubcategory', folderName)" :isChosen="isCategoryChosen(folderName)"
          :isPremium="isCategoryPremium(folderName)" :isShowFreeIcon=" (this.$store.state.connectedUser && this.$store.state.connectedUser.credits >= 1)">
        </FlagElementPreview>
      </span>
      <!-- <div v-for="(folderName, index) in this.getFoldersName" class="selectable-option position-relative"
        v-bind:key="'folder-' + index" v-on:click="$emit('setSubcategory', folderName)">
        <img class="position-absolute"
          v-bind:src="this.publicPath + this.folderCategoryOptions(folderName)[0]['image_url']">
      </div> -->
    </div>
    <div v-else>
      <div class="mt-1">
        <button class="connexion-button mb-2" @click="$emit('setSubcategory', '')">
          <i class="bi bi-chevron-left"></i> Retour</button>

        <!-- <button v-on:click="this.subCategory = ''">Retour</button> -->
      </div>
      <!-- <div v-for="(option, index) in this.chosenCategoryOptions" class="selectable-option position-relative"
        v-bind:key="'option-' + index" v-on:click="element_chosen(option['type'], option)"
        v-bind:class="this.selectedOptionClass(option['id'], option['type'])">
        <div class="position-absolute">
          <img class="option-img" v-bind:src="this.publicPath + option['image_url']">
        </div>
      </div> -->
      <span v-for="(option, index) in this.chosenCategoryOptions" v-bind:key="'option-' + index">
        <FlagElementPreview v-bind:imageUrl="[option['image_url']]"
          v-bind:isChosen="this.$store.state.chosenFlagElements[option['type']] && option['id'] == this.$store.state.chosenFlagElements[option['type']]['id']"
          v-bind:zoom="this.zoom" v-bind:isPremium="option['premium'] == 1" v-bind:isShowFreeIcon="true"
          @selected="this.element_chosen(option['type'], option)">
        </FlagElementPreview>
      </span>
    </div>
  </div>
</template>

<script>
import FlagElementPreview from '../FlagElementPreview.vue';

export default {
  name: "CategoryWithFolders",
  components: { FlagElementPreview },
  props: {
    flagElementsOptions: {
      type: Object,
      required: true
    },
    folderDisplayAllElements: {
      type: Boolean,
      required: false,
      default: false
    },
    subCategory: {
      type: String,
      required: true
    },
    zoom: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => ({
    publicPath: process.env.BASE_URL + "flag-elements-img/",
    chosenCategory: ""
  }),
  computed: {
    chosenCategoryOptions() {
      // console.log(this.flagElementsOptions[0]["image_url"])
      // return this.folderCategoryOptions(this.subCategory);
      return (this.folderCategoryOptions(this.subCategory).sort((option1, option2) => {
        console.log(option1)
        console.log(option2)
        if (option1.premium === '1' && option2.premium !== '1') {
          return 1; 
        } else if (option1.premium !== '1' && option2.premium === '1') {
            return -1; 
        } else {
            return 0;
        }
      }));
    },
    getFoldersName() {
      var folderNames = [];
      var folderName = "";
      this.flagElementsOptions.forEach(element => {
        folderName = element["image_url"].split("/")[1];
        if (folderName && !folderNames.includes(folderName))
          folderNames.push(folderName);
      })

      return (folderNames.sort((folderName1, folderName2) => {
        if (this.isCategoryPremium(folderName1) && !this.isCategoryPremium(folderName2)) {
          return 1;
        } else if (!this.isCategoryPremium(folderName1) && this.isCategoryPremium(folderName2)) {
          return -1;
        } else {
          return 0;
        }
      }));
    }
  },
  methods: {
    folderImageUrl(folderName) {
      var displayedImg = [this.folderCategoryOptions(folderName)[0]['image_url']]
      console.log(displayedImg)
      if (this.folderDisplayAllElements && this.folderCategoryOptions(folderName).length > 1)
        displayedImg.push(this.folderCategoryOptions(folderName)[1]['image_url'])
      return (displayedImg)
    },
    isCategoryChosen(folderName) {
      var options = this.folderCategoryOptions(folderName)

      for (let index = 0; index < options.length; index++) {
        if (this.$store.state.chosenFlagElements[options[index]['type']] && options[index]['id'] == this.$store.state.chosenFlagElements[options[index]['type']]['id'])
          return true
      }
      return false
    },

    isCategoryPremium(folderName) {
      /*if(this.$store.state.connectedUser && this.$store.state.connectedUser.credits > 0)
        return false;*/
      var options = this.folderCategoryOptions(folderName)

      for (let index = 0; index < options.length; index++) {
        if (options[index]['premium'] != 1)
          return false
      }
      return true
    },

    selectedOptionClass(id, type) {
      if (this.$store.state.chosenFlagElements[type] && id == this.$store.state.chosenFlagElements[type]["id"])
        return ("chosen");
      return ("");
    },
    element_chosen(type, element) {
      if (element.premium == 1 && !this.$store.state.popInPremium) {
        this.$store.state.popInPremium = true;
        if (this.$store.state.connectedUserUniqueCode == null || this.$store.state.connectedUser.credits == 0) {
          this.$store.state.displayCreditModal = true;
          return;
        }
      }
      if (this.$store.getters.getFlagElement(type) && this.$store.getters.getFlagElement(type).id == element.id) {
        console.log('if')
        this.$store.state.chosenFlagElements[type] = {}
        return
      }
      this.$store.state.chosenFlagElements[type] = element
    },
    elementChosenFunction(type, element_id) {
      return function () {
        if (this.$store.state.chosenFlagElements[type] == element_id) {
          this.$store.state.chosenFlagElements[type] = {}
          return
        }
        console.log("select")
        console.log("this.$store.state.chosenFlagElements[" + type + "]")
        console.log("= " + element_id['image_url'])
        this.$store.state.chosenFlagElements[type] = element_id;
        console.log(this.$store.state.chosenFlagElements[type])

      };
    },
    folderCategoryOptions(folderName) {
      console.log("folderCategoryOptions")
      console.log(this.flagElementsOptions.filter(part => part.image_url.includes(folderName)))
      return (this.flagElementsOptions.filter(part => part.image_url.includes(folderName)));
    }
  }
}
</script>

<style scoped>
.selectable-option {
  width: 109px;
  height: 109px;
  background-color: white;
  display: inline-block;
  border-radius: 14px;
  margin: 10px 8px;
  overflow: hidden;
}

.selectable-option img {
  height: 110px;
  left: -34px;
}

.chosen {
  /* border: 6px solid #DDBD3C; */
  outline: 6px solid #DDBD3C;

  box-shadow: 0px 4px 4px 0px #00000040;

  /* padding-left: -10px */
}

.connexion-button {
  color: black !important;
  background-color: transparent !important;
  border-color: black !important;
  padding-top: 6xp !important;
  padding-bottom: 6xp !important;
}

.connexion-button i {
  font-size: 19px;
}

@media screen and (max-width: 991px) {
  .selectable-option {
    width: 76px;
    height: 76px;
  }

  .selectable-option img {
    height: 76px;
    left: -50px;
  }

  .connexion-button {
    font-size: 16px;
    padding-top: 4xp !important;
    padding-bottom: 4xp !important;

  }

  .connexion-button i {
    font-size: 16px;
  }
}
</style>