<template lang="">
    <div>
        checkout vue
        <FlagCheckout></FlagCheckout>
    </div>
</template>
<script>
import FlagCheckout from '../components/FlagCheckout.vue'

export default {
    components: {FlagCheckout}
    
}
</script>
<style lang="">
    
</style>