<template>
  <VueFinalModal class="flex justify-center items-center modal-sign-up"
    content-class="flex flex-col max-w-xl m-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2">
    <h2 class="mb-4 title-police">Inscription</h2>
    E-mail
    <span class="text-danger font-weight-500">*</span>
    <input type="text" class="form-control mb-3 mt-2" placeholder="Email" aria-label="email" v-model="inputEmail">
    Mot de passe
    <span class="text-danger font-weight-500">*</span>
    <input type="text" class="form-control mb-3 mt-2" placeholder="Mot de passe" aria-label="password"
      v-model="inputPassword">
    Confirmer le mot de passe
    <span class="text-danger font-weight-500">*</span>
    <input type="text" class="form-control mb-3 mt-2" placeholder="Mot de passe" aria-label="password-confirm"
      v-model="inputConfirmPassword">
    <!-- Date de naissance
    <input type="date" class="form-control mb-3 mt-2" v-model="this.inputBirthdate" min="1997-01-01" max="2030-12-31" /> -->
    Genre
    <select v-model="this.inputGender" class="form-select mb-3 mt-2" aria-label="Default select example">
      <option value="" selected>Non renseigné</option>
      <option value="Homme">Homme</option>
      <option value="Femme">Femme</option>
      <option value="Autre">Autre</option>
    </select>
    <div class="w-100" style="text-align: right">
      <div>
        <button class="btn btn-primary connexion-button white-button border-0 fw-700"
          style="margin-right: 16px; font-weight: 600" v-on:click="this.closeModal">
          Annuler
        </button>
        <button class="btn btn-primary connexion-button yellow-button" style="font-weight: 600;"
          v-on:click="this.signUp">
          Créer un compte
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import axios from 'axios'
import { VueFinalModal } from 'vue-final-modal'



export default {
  name: "SignUpModal",
  components: { VueFinalModal },
  data() {
    return {
      inputEmail: "",
      inputPassword: "",
      inputConfirmPassword: "",
      inputBirthdate: "",
      inputGender: ""
    }
  },
  props: {
    closeModal: {
      type: Function
    }
  },
  methods: {
    checkEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    signUp() {
      if (!this.checkEmail(this.inputEmail)) {
        alert("Email incorrect")
        return
      }
      if (!this.inputEmail || !this.inputPassword) {
        alert("Email ou mot de passe manquant")
        return
      }
      if (this.inputConfirmPassword !== this.inputPassword) {
        alert("Les mots de passes ne sont pas identiques")
        return
      }

      const connectionLogs = {
        email: this.inputEmail,
        password: this.inputPassword,
        birthdate: this.inputBirthdate,
        gender: this.inputGender,
      };
      console.log("connexion :")
      console.log('email : ' + connectionLogs['email']);
      console.log('password : ' + connectionLogs['password'] + '\n');
      axios
        .post('https://we-are-pirates.herokuapp.com' + "/user/sign_up", connectionLogs)
        .then((response) => {
          if (response["data"] == "email") {
            alert("Email déjà utilisé")
          }
          else if (response["data"]) {
            console.log("toto");
            this.$store.state.fetch.addGlobalHeader('x-wap-token', response["data"].unique_code);
            console.log(response["data"]);
            this.$store.state.connectedUserUniqueCode = response["data"].unique_code;
            this.$store.state.connectedUser = response["data"];
            this.$store.state.isConnected = true;
            this.isConnected = true;
            document.cookie = "uniquecode=" + response["data"].unique_code;
            this.closeModal()
          }
          else {
            alert("Erreur lors de la création du compte")
          }
        })
        .catch((response) => {
          console.log(response);
          console.log("catch");
          this.isConnected = false;
        });
    }
  },
}
</script>

<style scoped>
.font-weight-500 {
  font-weight: 500;
}
</style>