<template>
    <div class="credits-info">
        {{ credits }} crédit<span v-if="credits > 1">s</span> <button class="btn brown" style="background-color: #3269c8 !important; color: white !important"
            @click="this.openPremiumCheckoutModal">Acheter des crédits</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "CreditBanner",
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters(['credits'])
    },
    methods: {
        openPremiumCheckoutModal() {
            this.$store.state.displayCreditModal = true;
        },
    }
};
</script>