<template>
  <div class="mb-3">
    <div class="question fw-bold d-flex" v-on:click="this.displayAnswer = !this.displayAnswer; this.turn = (this.turn + 0.5) % 1">
      <slot name="question"></slot>
      <div class="black-line"></div>
      <div>
        <div :style="{ transform: 'rotate('+ turn+'turn)'}">
          <i class="bi bi-chevron-down"></i>
        </div>
      </div>
    </div>
    <Transition>
    <p v-if="this.displayAnswer">
      <slot name="answer"></slot>
    </p>
  </Transition>
  </div>
</template>

<script>
export default {
  name: "QuestionWithHiddenAnswer",
  data: () => ({
    displayAnswer: false,
    turn: 0
  })
}
</script>

<style scopped>
.question {
  cursor: pointer
}

.black-line {
  height: 0px;
  border: 1px black solid;
  flex-grow: 1;
  margin-top: 11px;
  margin-left: 8px;
  margin-right: 8px;
  opacity: 0.3;
}

i {
  -webkit-text-stroke: 2px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media screen and (max-width: 991px) {
  .black-line {
    border: 0;
  }
}

</style>