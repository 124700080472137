<template>
  <div class="cgvcgu-view">
    <div class="cgvcgu-view-container">
      <div class="CGV mb-5">
        <h1 class="title-police">Conditions Générales de Vente</h1>
        <p>
          La Plateforme WeArePirates.net est un site internet édité à titre professionnel au sens de la loi 2004-575 du 21 juin
          2004.
          <br>
          Les présentes Conditions Générales de Vente régissent toute utilisation des Services et complètent les Conditions
          Générales d’Utilisation de la Plateforme.
          <br>
          Le Vendeur a pour activité la vente d’images numérique et de produits en ligne.
          <br>
          Le Vendeur assure notamment la commercialisation des biens et/ou services susmentionnés par l&#39;intermédiaire du
          site https://wearepirate.net. La liste des biens et services proposés à la vente en ligne par le Vendeur peut être
          consultée sur le site disponible à l’adresse https://wearepirate.net.
          <br>
          Les Parties conviennent que leurs relations seront régies exclusivement par le présent contrat, à l&#39;exclusion de
          toute condition préalablement disponible sur le site web du Vendeur.
          <br>
          Le Vendeur se réserve le droit de modifier à tout moment les présentes conditions générales de vente en publiant
          une nouvelle version sur le Site. Les conditions générales de vente sont celles en vigueur à la date de validation de
          la commande. Les Parties conviennent que les photos des Biens en vente sur le site https://wearepirate.net n&#39;ont
          aucune valeur contractuelle.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 1. DEFINITIONS</h1>
        <p>
          Les termes mentionnés ci-après ont la signification suivante dans les présentes Conditions Générales de Vente :
          <br>
          <br>
          <table>
            <tr>
              <td>« Article » ou « Biens »</td>
              <td>Le ou les Biens ayant fait l&#39;objet de la Commande ou proposé à la vente sur le Site</td>
            </tr>
            <tr>
              <td>« Client »</td>
              <td>Personne morale ou physique effectuant une Commande auprès du Vendeur</td>
            </tr>
            <tr>
              <td>« Compte Client »</td>
              <td>
                Désigne l’espace informatique hébergé sur la Plateforme et
                permettant à un Client d’utiliser la Plateforme et les Services,
                ce outre l’espace numérique lui permettant de stocker ses
                données personnelles
              </td>
            </tr>
            <tr>
              <td>« Conditions Générales de Vente » (CGV)</td>
              <td>Les conditions générales de vente qui font l&#39;objet des présentes ;</td>
            </tr>
            <tr>
              <td>« Conditions Générales d’Utilisation » (CGU)</td>
              <td>
                Désigne les conditions contractuelles mises à disposition sur
                la page d’accueil de la Plateforme, afin d’en encadrer son
                utilisation par tout Utilisateur
              </td>
            </tr>
            <tr>
              <td>« Contrat »</td>
              <td>
                Le présent acte, y compris son préambule et ses annexes
                ainsi que tout amendement, substitution, extension ou
                renouvellement intervenu aux présentes en vertu de la
                convention des Parties
              </td>
            </tr>
            <tr>
              <td>« la Plateforme » ou le « Site »</td>
              <td>
                Désigne le site de Vente en Ligne https://wearepirate.net
                utilisé par le Vendeur pour la commercialisation de ses
                services.
              </td>
            </tr>
            <tr>
              <td>« We Are Pirates »</td>
              <td>
                Désigne la société We Are Pirates, SASU, au capital de 1000
                euros, immatriculée sous le n° RCS 921 437 323, dont le siège
                social est sis 8 avenue Cousin de Méricourt - 94230 CACHAN
              </td>
            </tr>
            <tr>
              <td>« Prix »</td>
              <td>
                Désigne la valeur unitaire d&#39;un Bien ou d&#39;un Service ; cette
                valeur s&#39;entend toutes taxes comprises et hors Frais de
                Livraison
              </td>
            </tr>
            <tr>
              <td>« Prix total »</td>
              <td>
                Désigne le montant total des Prix cumulés des Biens et
                Services qui font l&#39;objet de la Commande ; ce montant
                s&#39;entend toutes taxes comprises
              </td>
            </tr>
            <tr>
              <td>« Services »</td>
              <td>
                Désigne l’ensemble des Services proposés par We Are Pirates
                aux Utilisateurs par l’intermédiaire de la Plateforme
              </td>
            </tr>
            <tr>
              <td>« Utilisateur »</td>
              <td>
                Désigne toute personne physique ou morale qui accède et
                navigue sur la Plateforme, qu’elle soit Utilisateur,
                administrateur ou tout internaute.
              </td>
            </tr>
            <tr>
              <td>« Vente en Ligne »</td>
              <td>
                Désigne la commercialisation des Biens et Services du Vendeur via le Site
              </td>
            </tr>
          </table>
          <br>
          Les références aux articles sont des références aux articles du présent Contrat, à moins qu&#39;il n&#39;en soit disposé
          autrement.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 2. OBJET</h1>
        <p>
          Le présent Contrat a pour objet de définir les droits et obligations des Parties dans le cadre de la Vente en Ligne
          des Biens et Services proposés à la vente par le Vendeur au Client.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 3. CHAMP D’APPLICATION</h1>
        <p>
          Les présentes Conditions Générales de Vente sont réservées aux seuls consommateurs, au sens qu&#39;en donnent la
          loi et la jurisprudence, agissant exclusivement pour leur propre compte.
          <br>
          Conformément aux articles L. 111-1 et L. 111-4 du Code de la consommation, les caractéristiques essentielles et les
          prix des Biens et Services vendus par voie électronique sont disponibles sur le Site.
          Le professionnel communique également au consommateur les informations relatives à son identité, à ses
          coordonnées postales, téléphoniques et électroniques et à ses activités, ainsi que, celles relatives aux garanties
          légales, notamment la garantie légale de conformité et la garantie légale des vices cachés, et des éventuelles
          garanties commerciales, ainsi que, le cas échéant, du service après-vente et les informations afférentes aux autres
          conditions contractuelles, conformément aux articles R. 111-1 et R. 111-2 du Code de la consommation.
          <br>
          Par ailleurs, le Client reçoit les informations prévues aux articles L. 221-5 et L. 221-11 du Code de la consommation,
          préalablement et postérieurement à la conclusion de la vente et notamment au moyen des présentes Conditions
          Générales de Vente.
          <br>
          Les présentes Conditions Générales de Vente sont applicables à toutes les ventes de Biens et Services par le
          Vendeur intervenues par le biais du Site.
          <br>
          Le Client déclare avoir pris connaissance des présentes Conditions Générales de Vente avant la Validation de la
          Commande au sens de l’ ARTICLE 5 . La Validation de la Commande vaut donc acceptation sans restriction ni réserve
          des présentes Conditions Générales de Vente.
        </p>
        <h1 class="subtitle-police">ARTICLE 4. ENTREE EN VIGUEUR ET DUREE</h1>
        <p>
          Le présent Contrat entre en vigueur à la date de Validation de la Commande telle que définie à l’ ARTICLE 5 .
          <br>
          Le Contrat est conclu pour la durée nécessaire à la fourniture des Biens et Services, jusqu&#39;à l&#39;extinction des
          garanties et obligations dues par le Vendeur.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 5. COMMANDE DE BIENS ET SERVICES ET ETAPES DE CONCLUSION DE LA VENTE EN LIGNE</h1>
        <p>
          Afin de réaliser la Commande, le Client devra obligatoirement suivre les étapes suivantes :
          <br>
          <br>
          5.1 Accès au site https://wearepirate.net
          <br>
          L’utilisateur doit se rendre sur le site https://wearepirate.net
          <br>
          <br>
          5.2 Acceptation des conditions générales d’utilisation et des conditions générales de vente
          <br>
          Avant de créer son drapeau, l&#39;utilisateur doit accepter les Conditions Générales de Vente (CGV), les Conditions
          Générales d&#39;Utilisation (CGU).
          <br>
          <br>
          5.3 Création de compte pour les nouveaux utilisateurs
          <br>
          Lorsqu&#39;un nouvel utilisateur valide son drapeau, il lui est demandé de créer un compte. Pour ce faire, il doit fournir
          son adresse e-mail, créer un mot de passe et le confirmer. De plus, il doit renseigner sa date de naissance et son
          genre, bien que ce dernier ne soit pas obligatoire (il peut choisir parmi les options : Homme, Femme ou Autre).
          <br>
          <br>
          5.4 Téléchargement gratuit pour les drapeaux non-payants
          <br>
          Si le drapeau de l&#39;utilisateur ne contient aucun élément payant, il peut télécharger son drapeau gratuitement, en
          qualité standard avec watermark.
          <br>
          <br>
          5.5 Téléchargement payant pour les drapeaux contenant des éléments payants et/ou sans watermark
          <br>
          Si le drapeau de l&#39;utilisateur ne contient que des éléments gratuits, mais qu&#39;il souhaite supprimer la watermark au
          téléchargement, il doit utiliser un crédit pour le téléchargement qualité premium sans watermark.
          Si le drapeau de l&#39;utilisateur contient un ou plusieurs éléments payants, il doit posséder des crédits pour effectuer
          le téléchargement. S&#39;il a suffisamment de crédits, il peut sélectionner la qualité désirée et lancer le
          téléchargement. S&#39;il n&#39;a pas assez de crédits, il doit acheter des crédits en cliquant sur le bouton &quot;Acheter des
          crédits&quot;.
          <br>
          <br>
          5.6 Accès au bouton « acheter des crédits »
          <br>
          Il existe trois façons d&#39;accéder au bouton &quot;Acheter des crédits&quot;:
          <br>
          i) En naviguant dans la barre de menu et en se rendant dans l&#39;onglet &quot;Mes crédits&quot;.
          <br>
          ii) Après la validation du drapeau, l&#39;utilisateur est redirigé vers la page &quot;Mon drapeau&quot; où se trouve le
          bouton &quot;Acheter des crédits&quot;.
          <br>
          iii) Lorsque l&#39;utilisateur sélectionne pour la première fois un élément payant, une fenêtre apparaît avec
          les options de tarification ainsi que le bouton &quot;Acheter des crédits&quot;.
          <br>
          <br>
          5.7 Achat de crédits
          <br>
          Lorsque l&#39;utilisateur clique sur « Acheter des crédits », une fenêtre affiche les options de tarification. L&#39;utilisateur
          sélectionne l&#39;option de son choix puis clique sur &quot;Acheter mon pack&quot;. À ce stade, Stripe prend le relais et une
          fenêtre s&#39;affiche où l&#39;utilisateur doit remplir ses coordonnées bancaires et valider.
          <br>
          <br>
          5.8 Attribution de crédits
          <br>
          Si le paiement est accepté, les crédits sont automatiquement ajoutés au compte de l&#39;Utilisateur. L’Utilisateur peut
          désormais télécharger son drapeau contenant des éléments payants.
          <br>
          L&#39;utilisateur peut retrouver l&#39;historique de ses paiements sur la page &quot;Mes Crédits&quot;
          Le Client reçoit ensuite par voie électronique et sans délai une confirmation d&#39;acceptation de paiement de la
          Commande.
          <br>
          Le Client reçoit également par voie électronique et sans délai un accusé réception valant confirmation de la
          Commande (la &quot; Confirmation de la Commande &quot;).
          <br>
          Le Client reçoit par voie électronique la confirmation de l&#39;expédition de la Commande.
          <br>
          Toutefois, conformément à l&#39;article L. 121-11 du Code de la consommation, le Vendeur se réserve le droit de
          refuser la Commande si elle est anormale, passée de mauvaise foi ou pour tout autre motif légitime, et en
          particulier, lorsqu&#39;il existe un litige avec le Client concernant le paiement d&#39;une commande antérieure ou une
          irrégularité au regard des Conditions Générales d’Utilisation du Site.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 6. PRIX DES BIENS ET SERVICES ET CONDITIONS DE VALIDITE</h1>
        <p>
          Le Prix des Biens et Services vendus sur le Site est indiqué respectivement par article et référence ou par
          prestation et par référence.
          <br>
          Au moment de la Validation de la Commande, le prix à payer s&#39;entend du Prix Tout Compris.
          <br>
          Les frais de télécommunication inhérents à l&#39;accès au Site restent à la charge exclusive du Client.
          <br>
          La durée de validité des offres et Prix est déterminée par l&#39;actualisation du Site.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 7. CONDITIONS DE PAIEMENT</h1>
        <p>
          Le paiement du Prix Tout Compris par le Client s&#39;effectue uniquement par carte bancaire. Les cartes bancaires
          acceptées sont celles des réseaux Carte Bleue, Visa, Eurocard / MasterCard.
          <br>
          La transaction est immédiatement débitée sur la carte bancaire du Client après vérification des données de celle-ci,
          à réception de l&#39;autorisation de débit de la part de la société émettrice de la carte bancaire utilisée par le Client.
          <br>
          Conformément à l&#39;article L. 132-2 du Code monétaire et financier, l&#39;engagement de payer donné au moyen d&#39;une
          carte de paiement est irrévocable. En communiquant les informations relatives à sa carte bancaire, le Client
          autorise le Vendeur à débiter sa carte bancaire du montant correspondant au Prix Tout Compris.
          <br>
          À cette fin, le Client confirme qu&#39;il est le titulaire de la carte bancaire à débiter et que le nom figurant sur la carte
          bancaire est effectivement le sien. Le Client communique les seize chiffres et la date d&#39;expiration de sa carte bleue
          ainsi que le cas échéant, les numéros du cryptogramme visuel.
          <br>
          Dans le cas où le débit du Prix Tout Compris serait impossible, la Vente en Ligne serait immédiatement résiliée de
          plein droit et la Commande serait annulée.
          <br>
          Le Vendeur met en œuvre tous les moyens pour assurer la confidentialité et la sécurité des données transmises sur
          le Site.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 8. GARANTIE</h1>
        <p>
          Le Vendeur ou le prestataire est soumis aux conditions de garanties légales prévues aux articles L. 217-3, L. 217-4,
          <br>
          L. 217-5 du Code de la consommation et aux articles 1641 et 1648 du Code civil, le cas échéant :
          <br>
          Art. L. 217-3 du Code de la consommation : &quot;Le vendeur délivre un bien conforme au contrat ainsi qu&#39;aux critères
          énoncés à l&#39;article L. 217-5.
          <br>
          Il répond des défauts de conformité existant au moment de la délivrance du bien au sens de l&#39;article L. 216-1, qui
          apparaissent dans un délai de deux ans à compter de celle-ci.
          <br>
          Dans le cas d&#39;un contrat de vente d&#39;un bien comportant des éléments numériques :
          <br>
          1° Lorsque le contrat prévoit la fourniture continue d&#39;un contenu numérique ou d&#39;un service numérique pendant une
          durée inférieure ou égale à deux ans, ou lorsque le contrat ne détermine pas la durée de fourniture, le vendeur
          répond des défauts de conformité de ce contenu numérique ou de ce service numérique qui apparaissent dans un
          délai de deux ans à compter de la délivrance du bien ;
          <br>
          2° Lorsque le contrat prévoit la fourniture continue d&#39;un contenu numérique ou d&#39;un service numérique pendant une
          durée supérieure à deux ans, le vendeur répond des défauts de conformité de ce contenu numérique ou de ce
          service numérique qui apparaissent au cours de la période durant laquelle celui-ci est fourni en vertu du contrat.
          <br>
          Pour de tels biens, le délai applicable ne prive pas le consommateur de son droit aux mises à jour conformément
          aux dispositions de l&#39;article L. 217-19.
          <br>
          <br>
          Le vendeur répond également, durant les mêmes délais, des défauts de conformité résultant de l&#39;emballage, des
          instructions de montage, ou de l&#39;installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée
          sous sa responsabilité, ou encore lorsque l&#39;installation incorrecte, effectuée par le consommateur comme prévu au
          contrat, est due à des lacunes ou erreurs dans les instructions d&#39;installation fournies par le vendeur.
          <br>
          Ce délai de garantie s&#39;applique sans préjudice des articles 2224 et suivants du code civil. Le point de départ de la
          prescription de l&#39;action du consommateur est le jour de la connaissance par ce dernier du défaut de conformité.&quot;
          Art. L. 217-4 du Code de la consommation : &quot; Le bien est conforme au contrat s&#39;il répond notamment, le cas
          échéant, aux critères suivants :
          <br>
          1° Il correspond à la description, au type, à la quantité et à la qualité, notamment en ce qui concerne la
          fonctionnalité, la compatibilité, l&#39;interopérabilité, ou toute autre caractéristique prévues au contrat ;
          <br>
          2° Il est propre à tout usage spécial recherché par le consommateur, porté à la connaissance du vendeur au plus
          tard au moment de la conclusion du contrat et que ce dernier a accepté ;
          <br>
          3° Il est délivré avec tous les accessoires et les instructions d&#39;installation, devant être fournis conformément au
          contrat ;
          <br>
          4° Il est mis à jour conformément au contrat.&quot;
          <br>
          <br>
          Art. L. 217-5 du Code de la consommation : &quot;I. - En plus des critères de conformité au contrat, le bien est conforme
          s&#39;il répond aux critères suivants :
          <br>
          1° Il est propre à l&#39;usage habituellement attendu d&#39;un bien de même type, compte tenu, s&#39;il y a lieu, de toute
          disposition du droit de l&#39;Union européenne et du droit national ainsi que de toutes les normes techniques ou, en
          l&#39;absence de telles normes techniques, des codes de conduite spécifiques applicables au secteur concerné ;
          <br>
          2° Le cas échéant, il possède les qualités que le vendeur a présentées au consommateur sous forme d&#39;échantillon ou
          de modèle, avant la conclusion du contrat ;
          <br>
          3° Le cas échéant, les éléments numériques qu&#39;il comporte sont fournis selon la version la plus récente qui est
          disponible au moment de la conclusion du contrat, sauf si les parties en conviennent autrement ;
          <br>
          4° Le cas échéant, il est délivré avec tous les accessoires, y compris l&#39;emballage, et les instructions d&#39;installation que
          le consommateur peut légitimement attendre ;
          <br>
          5° Le cas échéant, il est fourni avec les mises à jour que le consommateur peut légitimement attendre,
          conformément aux dispositions de l&#39;article L. 217-19 ;
          <br>
          6° Il correspond à la quantité, à la qualité et aux autres caractéristiques, y compris en termes de durabilité, de
          fonctionnalité, de compatibilité et de sécurité, que le consommateur peut légitimement attendre pour des biens de
          même type, eu égard à la nature du bien ainsi qu&#39;aux déclarations publiques faites par le vendeur, par toute
          personne en amont dans la chaîne de transactions, ou par une personne agissant pour leur compte, y compris dans
          la publicité ou sur l&#39;étiquetage.
          <br>
          <br>
          II. - Toutefois, le vendeur n&#39;est pas tenu par toutes déclarations publiques mentionnées à l&#39;alinéa qui précède s&#39;il
          démontre :
          <br>
          1° Qu&#39;il ne les connaissait pas et n&#39;était légitimement pas en mesure de les connaître ;
          <br>
          2° Qu&#39;au moment de la conclusion du contrat, les déclarations publiques avaient été rectifiées dans des conditions
          comparables aux déclarations initiales ; ou
          <br>
          3° Que les déclarations publiques n&#39;ont pas pu avoir d&#39;influence sur la décision d&#39;achat.
          <br>
          <br>
          III. - Le consommateur ne peut contester la conformité en invoquant un défaut concernant une ou plusieurs
          caractéristiques particulières du bien, dont il a été spécifiquement informé qu&#39;elles s&#39;écartaient des critères de
          conformité énoncés au présent article, écart auquel il a expressément et séparément consenti lors de la conclusion
          du contrat.&quot;
          <br>
          Art. 1641 du Code civil : &quot; Le vendeur est tenu de la garantie à raison des défauts cachés de la chose vendue qui la
          rendent impropre à l&#39;usage auquel on la destine, ou qui diminuent tellement cet usage que l&#39;acheteur ne l&#39;aurait
          pas acquise, ou n&#39;en aurait donné qu&#39;un moindre prix, s&#39;il les avait connus&quot;.
          <br>
          Art. 1648 du Code civil : &quot; L&#39;action résultant des vices rédhibitoires doit être intentée par l&#39;acquéreur dans un délai
          de deux ans à compter de la découverte du vice.
          Dans le cas prévu par l&#39;article 1642-1, l&#39;action doit être introduite, à peine de forclusion, dans l&#39;année qui suit la
          date à laquelle le vendeur peut être déchargé des vices ou des défauts de conformité apparents&quot;.
          Les dispositions qui précèdent ne sont pas exclusives de l&#39;application de la garantie légale de conformité prévue
          dans le Code de la consommation et de la garantie légale des vices cachés des articles 1641 et suivants du Code
          civil, conformément aux dispositions de l&#39;article L. 217-22 du Code de la consommation.
          <br>
          <br>
          Le client pourra exercer ces garanties en adressant sa demande à :
          <br>
          Monsieur Thomas AMUSAN, 8 avenue Cousin de Méricourt 94230, Cachan
          <br>
          Art. D. 211-4 du Code de la consommation (fourniture de contenu ou de service numérique en continue).
          Le consommateur a droit à la mise en œuvre de la garantie légale de conformité en cas d&#39;apparition d&#39;un défaut de
          conformité durant un délai de 2 ans à compter de la fourniture du contenu numérique ou du service numérique.
          Durant ce délai, le consommateur n&#39;est tenu d&#39;établir que l&#39;existence du défaut de conformité et non la date
          d&#39;apparition de celui-ci.
          <br>
          La garantie légale de conformité emporte obligation de fournir toutes les mises à jour nécessaires au maintien de
          <br>
          la conformité du contenu numérique ou du service numérique durant 2 ans.
          <br>
          La garantie légale de conformité donne au consommateur droit à la mise en conformité du contenu numérique ou
          du service numérique sans retard injustifié suivant sa demande, sans frais et sans inconvénient majeur pour lui.
          <br>
          Le consommateur peut obtenir une réduction du prix en conservant le contenu numérique ou le service
          numérique, ou il peut mettre fin au contrat en se faisant rembourser intégralement contre renoncement au
          contenu numérique ou au service numérique, si :
          <br>
          1° Le professionnel refuse de mettre le contenu numérique ou le service numérique en conformité ;
          <br>
          2° La mise en conformité du contenu numérique ou du service numérique est retardée de manière injustifiée ;
          <br>
          3° La mise en conformité du contenu numérique ou du service numérique ne peut intervenir sans frais imposés au
          consommateur ;
          <br>
          4° La mise en conformité du contenu numérique ou du service numérique occasionne un inconvénient majeur
          pour le consommateur ;
          <br>
          5° La non-conformité du contenu numérique ou du service numérique persiste en dépit de la tentative de mise en
          conformité du professionnel restée infructueuse.
          Le consommateur a également droit à une réduction du prix ou à la résolution du contrat lorsque le défaut de
          conformité est si grave qu&#39;il justifie que la réduction du prix ou la résolution du contrat soit immédiate. Le
          consommateur n&#39;est alors pas tenu de demander la mise en conformité du contenu numérique ou du service
          numérique au préalable.
          <br>
          <br>
          Dans les cas où le défaut de conformité est mineur, le consommateur n&#39;a droit à l&#39;annulation du contrat que si le
          contrat ne prévoit pas le paiement d&#39;un prix.
          Toute période d&#39;indisponibilité du contenu numérique ou du service numérique en vue de sa remise en conformité
          suspend la garantie qui restait à courir jusqu&#39;à la fourniture du contenu numérique ou du service numérique de
          nouveau conforme.
          <br>
          Ces droits résultent de l&#39;application des articles L. 224-25-1 à L. 224-25-31 du Code de la consommation.
          Le professionnel qui fait obstacle de mauvaise foi à la mise en œuvre de la garantie légale de conformité encourt
          une amende civile d&#39;un montant maximal de 300 000 euros, qui peut être porté jusqu&#39;à 10 % du chiffre d&#39;affaires
          moyen annuel (article L. 242-18-1 du Code de la consommation).
          <br>
          Le consommateur bénéficie également de la garantie légale des vices cachés en application des articles 1641 à
          1649 du Code civil, pendant une durée de deux ans à compter de la découverte du défaut. Cette garantie donne
          droit à une réduction de prix si le contenu numérique ou le service numérique est conservé, ou à un
          remboursement intégral contre renonciation au contenu numérique ou au service numérique.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 9. DROIT DE PROPRIETE INTELLECTUELLE</h1>
        <p>
          La marque du Vendeur « We Are Pirates » marque déposée sous le n°4916977 et publiée au BOPI 2022-51 le 23
          décembre 2022, ainsi que l&#39;ensemble des marques figuratives ou non et plus généralement toutes les autres
          marques, illustrations, images et logotypes figurant sur les Articles, leurs accessoires et leurs emballages, qu&#39;ils
          soient déposés ou non, sont et demeureront la propriété exclusive du Vendeur. Toute reproduction totale ou
          partielle, modification ou utilisation de ces marques, illustrations, images et logotypes, pour quelque motif et sur
          quelque support que ce soit, sans accord exprès et préalable du Vendeur, est strictement interdite. Il en est de
          même de toute combinaison ou conjonction avec toute autre marque, symbole, logotype et plus généralement
          tout signe distinctif destiné à former un logo composite.
          <br>
          Il en est de même pour tout droit d&#39;auteur, dessin, modèle et brevet qui sont la propriété du Vendeur.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 10. PROTECTION DES DONNEES A CARACTERE PERSONNEL</h1>
        <p>
          10.1 Collecte des données personnelles
          <br>
          Les données à caractère personnel qui sont collectées sur ce Site sont les suivantes :
          <br>
          Ouverture de compte - Lors de la création du compte de l&#39;utilisateur, ses nom, prénom, adresse électronique et
          date de naissance.
          <br>
          Connexion - Lors de la connexion de l&#39;utilisateur à la Plateforme, celle-ci enregistre, notamment, ses nom, prénom,
          données de connexion, d&#39;utilisation, de localisation et ses données relatives au paiement.
          <br>
          Profil - L&#39;utilisation des prestations prévues sur la Plateforme permet de renseigner un profil, pouvant comprendre
          une adresse et un numéro de téléphone.
          <br>
          Paiement - Dans le cadre du paiement des produits et prestations proposés sur la Plateforme, celle-ci enregistre
          des données financières relatives au compte bancaire ou à la carte de crédit de l&#39;utilisateur.
          <br>
          Communication - Lorsque la Plateforme est utilisée pour communiquer avec d&#39;autres membres, les données
          concernant les communications de l&#39;utilisateur font l&#39;objet d&#39;une conservation temporaire.
          <br>
          Cookies - Les cookies sont utilisés, dans le cadre de l&#39;utilisation du site. L&#39;utilisateur a la possibilité de désactiver les
          cookies à partir des paramètres de son navigateur.
          <br>
          <br>
          10.2 Utilisation des données personnelles
          <br>
          Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services de
          <br>
          la Plateforme, leur amélioration et le maintien d&#39;un environnement sécurisé. Plus précisément, les utilisations sont
          les suivantes :
          <br>
          - accès et utilisation de la Plateforme par l&#39;utilisateur ;
          <br>
          - gestion du fonctionnement et optimisation de la Plateforme ;
          <br>
          - organisation des conditions d&#39;utilisation des Services de paiement ;
          <br>
          - vérification, identification et authentification des données transmises par l&#39;utilisateur ;
          <br>
          - proposition à l&#39;utilisateur de la possibilité de communiquer avec d&#39;autres utilisateurs de la Plateforme ;
          <br>
          - mise en œuvre d&#39;une assistance utilisateurs ;
          <br>
          - personnalisation des services en affichant des publicités en fonction de l&#39;historique de navigation de l&#39;utilisateur,
          selon ses préférences ;
          <br>
          - prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des
          incidents de sécurité ;
          <br>
          - gestion des éventuels litiges avec les utilisateurs ;
          <br>
          - envoi d&#39;informations commerciales et publicitaires, en fonction des préférences de l&#39;utilisateur.
          <br>
          <br>
          10.3 Partage des données personnelles avec des tiers
          <br>
          Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants :
          <br>
          - quand l&#39;utilisateur utilise les services de paiement, pour la mise en œuvre de ces services, la Plateforme est en
          relation avec des sociétés bancaires et financières tierces avec lesquelles elle a passé des contrats ;
          <br>
          - lorsque l&#39;utilisateur publie, dans les zones de commentaires libres de la Plateforme, des informations accessibles
          au public ;
          <br>
          - quand l&#39;utilisateur autorise le site web d&#39;un tiers à accéder à ses données ;
          <br>
          - quand la Plateforme recourt aux services de prestataires pour fournir l&#39;assistance utilisateurs, la publicité et les
          services de paiement. Ces prestataires disposent d&#39;un accès limité aux données de l&#39;utilisateur, dans le cadre de
          l&#39;exécution de ces prestations, et ont une obligation contractuelle de les utiliser en conformité avec les dispositions
          de la réglementation applicable en matière protection des données à caractère personnel ;
          <br>
          - si la loi l&#39;exige, la Plateforme peut effectuer la transmission de données pour donner suite aux réclamations
          présentées contre la Plateforme et se conformer aux procédures administratives et judiciaires ;
          <br>
          - si la Plateforme est impliquée dans une opération de fusion, acquisition, cession d&#39;actifs ou procédure de
          redressement judiciaire, elle pourra être amenée à céder ou partager tout ou partie de ses actifs, y compris les
          données à caractère personnel. Dans ce cas, les utilisateurs seraient informés, avant que les données à caractère
          personnel ne soient transférées à une tierce partie.
          <br>
          <br>
          10.4 Transfert de données personnelles
          <br>
          En raison de l&#39;organisation de la société responsable de la Plateforme, au sein d&#39;un groupe international,
          l&#39;utilisateur autorise la Plateforme à transférer, stocker et traiter ses informations aux États-Unis. Les lois en
          vigueur dans ce pays peuvent différer des lois applicables dans le lieu de résidence de l&#39;utilisateur, au sein de
          l&#39;Union européenne. En utilisant la Plateforme, l&#39;utilisateur consent au transfert de ses données personnelles vers
          les États-Unis.
          <br>
          La Plateforme demeure responsable des données personnelles qui sont partagées avec des tiers dans le cadre du
          Privacy Shield.
          <br>
          La Plateforme se conforme aux règles du Privacy Shield, bouclier de protection des données entre l&#39;Union
          européenne et les États-Unis, tel que formulé par le Département du commerce américain relatif à la collecte,
          l&#39;utilisation et la conservation des données à caractère personnel transférées aux États-Unis depuis l&#39;Union
          européenne. La Plateforme a déclaré au Département du commerce américain qu&#39;elle adhère aux principes du
          Privacy Shield. En cas de conflit entre les conditions de la clause et les principes du Privacy Shield, ces derniers
          prévaudront.
          <br>
          <br>
          10.5 Sécurité et confidentialité
          <br>
          La Plateforme met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de
          sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non
          autorisés. Toutefois, il est à signaler qu&#39;internet n&#39;est pas un environnement complètement sécurisé et la
          Plateforme ne peut pas garantir la sécurité de la transmission ou du stockage des informations sur internet.
          <br>
          <br>
          10.6 Mise en œuvre des droits des utilisateurs
          <br>
          En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des
          droits suivants :
          <br>
          - ils peuvent mettre à jour ou supprimer les données qui les concernent en se connectant à leur compte et en
          configurant les paramètres de ce compte ;
          <br>
          - ils peuvent supprimer leur compte, en écrivant à l&#39;adresse électronique suivante : ..... (à compléter). Il est à noter
          que les informations partagées avec d&#39;autres utilisateurs, comme les publications sur les forums, peuvent rester
          visibles du public sur la Plateforme, même après la suppression de leur compte ;
          <br>
          - ils peuvent exercer leur droit d&#39;accès, pour connaître les données personnelles les concernant, en écrivant à
          l&#39;adresse électronique suivante : ..... (à compléter). Dans ce cas, avant la mise en oeuvre de ce droit, la Plateforme
          peut demander une preuve de l&#39;identité de l&#39;utilisateur afin d&#39;en vérifier l&#39;exactitude ;
          <br>
          - si les données à caractère personnel détenues par la Plateforme sont inexactes, ils peuvent demander la mise à
          jour des informations, en écrivant à l&#39;adresse électronique suivante : ..... (à compléter) ;
          <br>
          - les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois
          applicables en matière de protection des données, en écrivant à l&#39;adresse électronique suivante : ..... (à
          compléter).
          <br>
          <br>
          10.7 Évolution de la présente clause
          <br>
          La Plateforme se réserve le droit d&#39;apporter toute modification à la présente clause relative à la protection des
          données à caractère personnel à tout moment. Si une modification est apportée à la présente clause de protection
          des données à caractère personnel, la Plateforme s&#39;engage à publier la nouvelle version sur son site. La Plateforme
          informera également les utilisateurs de la modification par messagerie électronique, dans un délai minimum de 15
          jours avant la date d&#39;effet. Si l&#39;utilisateur n&#39;est pas d&#39;accord avec les termes de la nouvelle rédaction de la clause de
          protection des données à caractère personnel, il a la possibilité de supprimer son compte.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 11. FORCE MAJEURE</h1>
        <p>
          L&#39;exécution par le Vendeur de ses obligations aux termes du présent Contrat sera suspendue en cas de survenance
          d&#39;un cas fortuit ou de force majeure qui en gênerait ou en retarderait l&#39;exécution.
          <br>
          Le Vendeur avisera le Client de la survenance d&#39;un tel cas fortuit ou de force majeure dans les 15 jours à compter
          de la date de survenance de l&#39;événement.
          <br>
          Lorsque la suspension de l&#39;exécution des obligations du Vendeur se poursuit pendant une période supérieure à 15
          jours, le Client a la possibilité de résilier la Commande en cours et le Vendeur procédera alors au remboursement
          de la Commande dans les conditions visées à l&#39;Article 7.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 12. NULLITE D’UNE CLAUSE DU CONTRAT</h1>
        <p>
          Si l&#39;une quelconque des dispositions du présent Contrat était annulée, cette nullité n&#39;entraînerait pas la nullité des
          autres dispositions du Contrat qui demeureront en vigueur entre les Parties.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 13. INDEPENDANCE DES PARTIES</h1>
        <p>
          Aucune des parties ne peut prendre d&#39;engagement au nom et/ou pour le compte de l&#39;autre Partie. Par ailleurs,
          chacune des Parties demeure seule responsable de ses allégations, engagements, prestations, produits et
          personnels.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 14. NON-RENONCIATION</h1>
        <p>
          Le fait pour l&#39;une des Parties de ne pas se prévaloir d&#39;un engagement par l&#39;autre Partie à l&#39;une quelconque des
          obligations visées par les présentes, ne saurait être interprété pour l&#39;avenir comme une renonciation à l&#39;obligation
          en cause.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 15. NOTIFICATIONS</h1>
        <p>
          Toutes notifications devant être effectuées dans le cadre du présent Contrat seront considérées comme réalisées
          si elles sont faites par lettre recommandée avec demande d&#39;avis de réception aux adresses suivantes respectives
          du Client et du Vendeur.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 16. RESPONSABILITE</h1>
        <p>
          Il est expressément convenu que l&#39;étendue de la responsabilité du vendeur pour les dommages directs liés à
          l&#39;exécution du présent contrat ne peut dépasser le montant des sommes payées au vendeur, au titre de la vente
          des marchandises.
          <br>
          Par ailleurs la responsabilité du vendeur ne pourra être engagée au titre des dommages indirects subis par
          l&#39;acheteur. Par dommages indirects, les parties conviennent d&#39;entendre notamment les pertes de bénéfice, chiffre
          d&#39;affaires, données ou usage de celles-ci.
          <br>
          La responsabilité du vendeur ne pourra être engagée au titre de tout usage contraire principes essentiels de We
          Are Pirates tels que définis dans les Conditions Générales d’Utilisation.
          <br>
          Ces stipulations qui répartissent le risque entre les parties ont pour le vendeur un caractère essentiel, les prix
          proposés et convenus reflétant cette répartition du risque et la limitation de responsabilité qui en résulte.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 17. RECLAMATIONS ET REGLEMENT AMIABLE DES LITIGES</h1>
        <p>
          En vertu de l&#39;article L. 612-1 du Code de la consommation &quot;Tout consommateur a le droit de recourir gratuitement
          à un médiateur de la consommation en vue de la résiliation amiable du litige qui l&#39;oppose à un professionnel.&quot;
          <br>
          Les litiges entrant dans le champ d&#39;application de l&#39;article L. 612-1 du Code de la consommation sont les litiges
          définis à l&#39;article L. 611-1 du Code de la consommation à savoir les litiges de nature contractuelle, portant sur
          l&#39;exécution d&#39;un contrat de vente ou de fournitures de services, opposant un consommateur à un professionnel. Le
          texte couvre les litiges nationaux et les litiges transfrontaliers.
          <br>
          Pour toute difficulté, nous vous invitons à nous contacter préalablement ou à contacter notre service après- vente
          :
          <br>
          <br>
          Coordonnées du service après-vente : 06 03 08 40 74
          <br>
          Adresse électronique du service après-vente : wearepirate.cie@gmail.com
          <br>
          Une plateforme de règlement des litiges en ligne est à votre disposition : Médiateur de la Fédération
          professionnelle du e-commerce et de la vente à distance (FEVAD)
          <br>
          Seules les réclamations relatives à la Vente en Ligne des Articles seront prises en compte.
          <br>
          Dans l&#39;année qui suivra votre demande auprès de nos services, en application de l&#39;article R. 616-1 du Code de la
          consommation, vous pourrez faire examiner votre demande par un médiateur.
          <br>
          Vous pouvez, à vos frais, vous faire assister par un conseil.
          <br>
          <br>
        </p>
        <h1 class="subtitle-police">ARTICLE 18. LOI APPLICABLE</h1>
        <p>
          Le présent Contrat sera régi par la loi française.
        </p>
      </div>
      <div class="CGU">
        <h1 class="title-police">Conditions Générales d’Utilisation</h1>
        <p>
          En vigueur au 30 mars 2024

          La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et conditions d&#39;utilisation
          qui suivent.
          La version actuellement en ligne de ces conditions d&#39;utilisation est la seule opposable pendant toute la durée
          d&#39;utilisation du site et jusqu&#39;à ce qu&#39;une nouvelle version la remplace.
          La Plateforme https://wearepirates.net est un site internet édité à titre professionnel au sens de la loi 2004-575 du
          21 juin 2004.
          Les présentes Conditions Générales d’Utilisation régissent toute utilisation du site internet
          https://wearepirates.net et complètent les Conditions Générales de Vente.
          Les présentes Conditions Générales d’Utilisation (ci-après les « CGU ») ont pour objet l’encadrement juridique des
          modalités de mise à disposition du site et des services par We Are Pirates et de définir les conditions d’accès et
          d’utilisation de services par toute personne souhaitant y accéder (ci-après « l’Utilisateur »).
          Les présentes CGU sont accessibles sur le site internet de We Are Pirates à la rubrique « CGU ».

        </p>
        <h1 class="subtitle-police">ARTICLE 1. INFORMATIONS LEGALES</h1>
        <p>
          1.1 Site (ci-après le « Site Web ») : https://wearepirates.net
          <br>
          <br>
          1.2 Éditeur (ci-après « l&#39;éditeur ») : We Are Pirates, SASU au capital de 1000 euros, dont le siège social est situé 8
          avenue Cousin de Méricourt, 94230 Cachan, RCS n° 921437323 représentée par Monsieur Thomas AMUSAN en sa
          qualité de président.
          <br>
          N° de TVA Intracommunautaire : FR64921437323
          <br>
          Numéro de téléphone : 0603084074
          <br>
          Adresse de courrier électronique : wearepirates.cie@gmail.com
          <br>
          Directeur de la publication : Monsieur Thomas AMUSAN
          <br>
          <br>
          1.3 Conception et réalisation : Monsieur Antoine ALIS, entrepreneur individuel immatriculée sous le SIREN
          n°877859595, dont le siège social est sis Le Mas d’Agenais (47430).
          <br>
          <br>
          1.4 Hébergeur (ci-après « l&#39;hébergeur ») : Ce site est hébergé par OVH SAS 2 rue Kellermann - 59100 Roubaix –
          France. OVH SAS est une filiale de la société OVH Groupe SA, société immatriculée au RCS de Lille sous le numéro
          537 407 926 sise 2, rue Kellermann, 59100 Roubaix. SAS au capital de 10 174 560 €, immatriculée au RCS Lille
          Métropole 424 761 419 00045. Code APE 2620Z, N° TVA : FR 22 424 761 419.

        </p>
        <h1 class="subtitle-police">ARTICLE 2. ACCES AU SITE</h1>
        <p>
          L&#39;accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas
          utiliser ce site et les informations ou données qui y figurent à des fins commerciales, politiques, publicitaires et
          pour toute forme de sollicitation commerciale et notamment l&#39;envoi de courriers électroniques non sollicités.
          Le Site Web permet à l’Utilisateur un accès gratuit aux services proposés par We Are Pirates.
          <br>
          <br>
          Le Site Web est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais
          supportés par l’Utilisateur pour accéder au service (matériel informatique, logiciels, connexion internet, etc.)
          restent à sa charge.
        </p>
        <h1 class="subtitle-police">ARTICLE 3. CONTENU DU SITE</h1>
        <p>
          Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo,
          sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce site et
          plus généralement tous les éléments reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre
          de la propriété intellectuelle.
          <br>
          Ils sont la propriété pleine et entière de l&#39;éditeur ou de ses partenaires. Toute reproduction, représentation,
          utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les
          applications informatiques, sans l&#39;accord préalable et écrit de l&#39;éditeur, sont strictement interdites. Le fait pour
          l&#39;éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut
          pas acceptation desdites utilisations et renonciation aux poursuites.
        </p>
        <h1 class="subtitle-police">ARTICLE 4. GESTION DU SITE</h1>
        <p>
          Pour la bonne gestion du site, l&#39;éditeur pourra à tout moment :
          <br>
          - suspendre, interrompre ou de limiter l&#39;accès à tout ou partie du site, réserver l&#39;accès au site, ou à certaines
          parties du site, à une catégorie déterminée d&#39;internaute ;
          <br>
          - supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois
          nationales ou internationales, ou avec les règles de la Nétiquette ;
          <br>
          - suspendre le site afin de procéder à des mises à jour.

        </p>
        <h1 class="subtitle-police">ARTICLE 5. PRINCIPES ESSENTIELS D’UTILISATION DE WE ARE PIRATES</h1>
        <p>
          L’utilisation des produits et services WE ARE PIRATES doit refléter ses valeurs, notamment celles de la liberté
          d’expression et de créativité. L’utilisateur est libre de concevoir et personnaliser ses achats en créant un avatar
          unique, sous réserve de ne pas enfreindre la loi du 29 juillet 1881 sur la liberté de la presse et en particulier les
          dispositions relatives à la haine en ligne.
          <br>
          Les principes essentiels relatifs à l’interdiction de la discrimination étant entendue comme toute distinction opérée
          entre les personnes physiques ou morale sur le fondement de leur origine, de leur sexe, de leur situation de
          famille, de leur grossesse, de leur apparence physique, de la particulière vulnérabilité résultant de leur situation
          économique, apparente ou connue de son auteur, de leur patronyme, de leur lieu de résidence, de leur état de
          santé, de leur perte d&#39;autonomie, de leur handicap, de leurs caractéristiques génétiques, de leurs mœurs, de leur
          orientation sexuelle, de leur identité de genre, de leur âge, de leurs opinions politiques, de leurs activités
          syndicales devront également être respectés.
        </p>
        <h1 class="subtitle-police">ARTICLE 6. PROPRIETE INTELLECTUELLE</h1>
        <p>
          La marque du Vendeur « We Are Pirates » marque déposée sous le n°4916977 et publiée au BOPI 2022-51 le 23
          décembre 2022, ainsi que l&#39;ensemble des marques figuratives ou non et plus généralement toutes les autres
          marques, illustrations, images et logotypes, leurs accessoires et leurs emballages, qu&#39;ils soient déposés ou non,
          sont et demeureront la propriété exclusive du Vendeur. Toute reproduction totale ou partielle, modification ou
          utilisation de ces marques, illustrations, images et logotypes, pour quelque motif et sur quelque support que ce
          soit, sans accord exprès et préalable du Vendeur, est strictement interdite. Il en est de même de toute
          combinaison ou conjonction avec toute autre marque, symbole, logotype et plus généralement tout signe distinctif
          destiné à former un logo composite.
          <br>
          Il en est de même pour tout droit d&#39;auteur, dessin, modèle et brevet qui sont la propriété du Vendeur.
          <br>
          L’Utilisateur doit solliciter l’autorisation préalable du site pour toute reproduction, publication, copie des différents
          contenus. Il s’engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des
          fins commerciales et publicitaire est strictement interdite.
          <br>
          Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de
          l’exploitant du site Internet Constituerait une contrefaçon sanctionnée par l’article L. 335-2 et suivantes du Code
          de la propriété intellectuelle.
          <br>
          Il est rappelé conformément à l’article L. 122-5 du Code de la propriété intellectuelle que l’Utilisateur qui
          reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
        </p>
        <h1 class="subtitle-police">ARTICLE 7. RESPONSABILITE</h1>
        <p>
          La responsabilité de l&#39;éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de
          fonctionnement, empêchant l&#39;accès au site ou à une de ses fonctionnalités.
          <br>
          Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes
          les mesures appropriées pour protéger votre matériel et vos propres données notamment d&#39;attaques virales par
          Internet. Vous êtes par ailleurs le seul responsable des sites et données que vous consultez.
          <br>
          L&#39;éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :
          <br>
          - du fait de l&#39;usage du site ou de tout service accessible via Internet ;
          <br>
          - du fait du non-respect par vous des présentes conditions générales.
          <br>
          L&#39;éditeur n&#39;est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait
          de votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait.
          <br>
          Si l&#39;éditeur venait à faire l&#39;objet d&#39;une procédure amiable ou judiciaire à raison de votre utilisation du site, il pourra
          retourner contre vous pour obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui
          pourraient découler de cette procédure.
        </p>
        <h1 class="subtitle-police">ARTICLE 8. LIENS HYPERTEXTES</h1>
        <p>
          La mise en place par vous de tous liens hypertextes vers tout ou partie du site est strictement interdite, sauf
          autorisation préalable et écrite de l&#39;éditeur, sollicitée par courriel à l&#39;adresse suivante :
          wearepirates.cie@gmail.com
          <br>
          L&#39;éditeur est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce soit sa décision.
          Dans le cas où l&#39;éditeur accorderait son autorisation, celle-ci n&#39;est dans tous les cas que temporaire et pourra être
          retirée à tout moment, sans obligation de justification à la charge de l&#39;éditeur.
          Dans tous les cas, tout lien devra être retiré sur simple demande de l&#39;éditeur.
          <br>
          Toute information accessible via un lien vers d&#39;autres sites n&#39;est pas sous le contrôle de l&#39;éditeur qui décline toute
          responsabilité quant à leur contenu.

        </p>
        <h1 class="subtitle-police">ARTICLE 9. PROTECTION DES DONNEES A CARACTERE PERSONNEL</h1>
        <p>
          9.1 Collecte des données personnelles
          <br>
          Les données à caractère personnel qui sont collectées sur ce Site sont les suivantes :
          Ouverture de compte - Lors de la création du compte de l&#39;utilisateur, ses nom, prénom, adresse électronique et
          date de naissance.
          <br>
          Connexion - Lors de la connexion de l&#39;utilisateur à la Plateforme, celle-ci enregistre, notamment, ses nom, prénom,
          données de connexion, d&#39;utilisation, de localisation et ses données relatives au paiement.
          <br>
          Profil - L&#39;utilisation des prestations prévues sur la Plateforme permet de renseigner un profil, pouvant comprendre
          une adresse et un numéro de téléphone.
          <br>
          Paiement - Dans le cadre du paiement des produits et prestations proposés sur la Plateforme, celle-ci enregistre
          des données financières relatives au compte bancaire ou à la carte de crédit de l&#39;utilisateur.
          <br>
          Communication - Lorsque la Plateforme est utilisée pour communiquer avec d&#39;autres membres, les données
          concernant les communications de l&#39;utilisateur font l&#39;objet d&#39;une conservation temporaire.
          <br>
          Cookies - Les cookies sont utilisés, dans le cadre de l&#39;utilisation du site. L&#39;utilisateur a la possibilité de désactiver les
          cookies à partir des paramètres de son navigateur.
          <br>
          <br>
          9.2 Utilisation des données personnelles
          <br>
          Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services de
          la Plateforme, leur amélioration et le maintien d&#39;un environnement sécurisé. Plus précisément, les utilisations sont
          les suivantes :
          <br>
          - accès et utilisation de la Plateforme par l&#39;utilisateur ;
          <br>
          - gestion du fonctionnement et optimisation de la Plateforme ;
          <br>
          - organisation des conditions d&#39;utilisation des Services de paiement ;
          <br>
          - vérification, identification et authentification des données transmises par l&#39;utilisateur ;
          <br>
          - proposition à l&#39;utilisateur de la possibilité de communiquer avec d&#39;autres utilisateurs de la Plateforme ;
          <br>
          - mise en œuvre d&#39;une assistance utilisateurs ;
          <br>
          - personnalisation des services en affichant des publicités en fonction de l&#39;historique de navigation de l&#39;utilisateur,
          selon ses préférences ;
          <br>
          - prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des
          incidents de sécurité ;
          <br>
          - gestion des éventuels litiges avec les utilisateurs ;
          <br>
          - envoi d&#39;informations commerciales et publicitaires, en fonction des préférences de l&#39;utilisateur.
          <br>
          <br>
          9.3 Partage des données personnelles avec des tiers
          <br>
          Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants :
          <br>
          - quand l&#39;utilisateur utilise les services de paiement, pour la mise en œuvre de ces services, la Plateforme est en
          <br>
          relation avec des sociétés bancaires et financières tierces avec lesquelles elle a passé des contrats ;
          <br>
          - lorsque l&#39;utilisateur publie, dans les zones de commentaires libres de la Plateforme, des informations accessibles
          au public ;
          <br>
          - quand l&#39;utilisateur autorise le site web d&#39;un tiers à accéder à ses données ;
          <br>
          - quand la Plateforme recourt aux services de prestataires pour fournir l&#39;assistance utilisateurs, la publicité et les
          services de paiement. Ces prestataires disposent d&#39;un accès limité aux données de l&#39;utilisateur, dans le cadre de
          l&#39;exécution de ces prestations, et ont une obligation contractuelle de les utiliser en conformité avec les dispositions
          de la réglementation applicable en matière protection des données à caractère personnel ;
          <br>
          - si la loi l&#39;exige, la Plateforme peut effectuer la transmission de données pour donner suite aux réclamations
          présentées contre la Plateforme et se conformer aux procédures administratives et judiciaires ;
          <br>
          - si la Plateforme est impliquée dans une opération de fusion, acquisition, cession d&#39;actifs ou procédure de
          redressement judiciaire, elle pourra être amenée à céder ou partager tout ou partie de ses actifs, y compris les
          données à caractère personnel. Dans ce cas, les utilisateurs seraient informés, avant que les données à caractère
          personnel ne soient transférées à une tierce partie.
          <br>
          <br>
          9.4 Transfert de données personnelles
          <br>
          En raison de l&#39;organisation de la société responsable de la Plateforme, au sein d&#39;un groupe international,
          l&#39;utilisateur autorise la Plateforme à transférer, stocker et traiter ses informations aux États-Unis. Les lois en
          vigueur dans ce pays peuvent différer des lois applicables dans le lieu de résidence de l&#39;utilisateur, au sein de
          l&#39;Union européenne. En utilisant la Plateforme, l&#39;utilisateur consent au transfert de ses données personnelles vers
          les États-Unis.
          <br>
          La Plateforme demeure responsable des données personnelles qui sont partagées avec des tiers dans le cadre du
          Privacy Shield.
          <br>
          La Plateforme se conforme aux règles du Privacy Shield, bouclier de protection des données entre l&#39;Union
          européenne et les États-Unis, tel que formulé par le Département du commerce américain relatif à la collecte,
          l&#39;utilisation et la conservation des données à caractère personnel transférées aux États-Unis depuis l&#39;Union
          européenne. La Plateforme a déclaré au Département du commerce américain qu&#39;elle adhère aux principes du
          Privacy Shield. En cas de conflit entre les conditions de la clause et les principes du Privacy Shield, ces derniers
          prévaudront.
          <br>
          <br>
          9.5 Sécurité et confidentialité
          <br>
          La Plateforme met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de
          sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non
          autorisés. Toutefois, il est à signaler qu&#39;internet n&#39;est pas un environnement complètement sécurisé et la
          Plateforme ne peut pas garantir la sécurité de la transmission ou du stockage des informations sur internet.
          <br>
          <br>
          9.6 Mise en œuvre des droits des utilisateurs
          <br>
          En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des
          droits suivants :
          <br>
          - ils peuvent mettre à jour ou supprimer les données qui les concernent en se connectant à leur compte et en
          configurant les paramètres de ce compte ;
          <br>
          - ils peuvent supprimer leur compte, en écrivant à l&#39;adresse électronique suivante : wearepirates.cie@gmail.com.
          Il est à noter que les informations partagées avec d&#39;autres utilisateurs, comme les publications sur les forums,
          peuvent rester visibles du public sur la Plateforme, même après la suppression de leur compte ;
          <br>
          - ils peuvent exercer leur droit d&#39;accès, pour connaître les données personnelles les concernant, en écrivant à
          l&#39;adresse électronique suivante : wearepirates.cie@gmail.com. Dans ce cas, avant la mise en œuvre de ce droit, la
          Plateforme peut demander une preuve de l&#39;identité de l&#39;utilisateur afin d&#39;en vérifier l&#39;exactitude ;
          <br>
          - si les données à caractère personnel détenues par la Plateforme sont inexactes, ils peuvent demander la mise à
          jour des informations, en écrivant à l&#39;adresse électronique suivante : wearepirates.cie@gmail.com.
          <br>
          - les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois
          applicables en matière de protection des données, en écrivant à l&#39;adresse électronique suivante :
          wearepirates.cie@gmail.com.
          <br>
          <br>
          9.7 Évolution de la présente clause
          <br>
          La Plateforme se réserve le droit d&#39;apporter toute modification à la présente clause relative à la protection des
          données à caractère personnel à tout moment. Si une modification est apportée à la présente clause de protection
          des données à caractère personnel, la Plateforme s&#39;engage à publier la nouvelle version sur son site. La Plateforme
          informera également les utilisateurs de la modification par messagerie électronique, dans un délai minimum de 15
          jours avant la date d&#39;effet. Si l&#39;utilisateur n&#39;est pas d&#39;accord avec les termes de la nouvelle rédaction de la clause de
          protection des données à caractère personnel, il a la possibilité de supprimer son compte.
        </p>
        <h1 class="subtitle-police">ARTICLE 10. COOKIES</h1>
        <p>
          Le site peut collecter automatiquement des informations standards. Toutes les informations collectées
          indirectement ne seront utilisées que pour suivre le volume, le type et la configuration du trafic utilisant ce site,
          pour en développer la conception et l&#39;agencement et à d&#39;autres fins administratives et de planification et plus
          généralement pour améliorer le service que nous vous offrons.
        </p>
        <h1 class="subtitle-police">ARTICLE 11. PHOTOGRAPHIES ET REPRESENTATION DES PRODUITS</h1>
        <p>
          Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et n&#39;engagent pas
          l&#39;éditeur.

        </p>
        <h1 class="subtitle-police">ARTICLE 12. LOI APPLICABLE ET JURIDICTION COMPETENTE</h1>
        <p>
          Les présentes Conditions Générales sont régies par la loi française.
          <br>
          Tout litige résultant de la formation, de l&#39;interprétation ou de l&#39;exécution des présentes CGU entre personnes
          ayant la qualité de commerçant sera de la compétence exclusive des tribunaux du ressort de Paris, nonobstant
          pluralité de défendeurs ou appel en garantie.
          <br>
          Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées
          inscrites à l’Article 1 des présentes CGU.
        </p>
        <h1 class="subtitle-police">ARTICLE 13. CONTACTEZ-NOUS</h1>
        <p>
          Pour toute question, information sur les produits présentés sur le site, ou concernant le site lui-même, vous
          pouvez laisser un message à l&#39;adresse suivante : wearepirates.cie@gmail.com
        </p>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "CgvCguView"
};
</script>

<style scoped>
.cgvcgu-view {
  margin: 56px;
}

.cgvcgu-view-container {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  max-width: 1616px;
  /* margin: 56px; */
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

@media screen and (max-width: 991px) {
  .cgvcgu-view-container {
    background-color: transparent;
    padding: 0;
  }

  .cgvcgu-view {
    margin: 28px;
  }
}

table, th, td {
  border: 1px solid;
  padding: 5px;
}

.subtitle-police {
  margin-bottom: 35px;
  font-size: 25px;
}
</style>