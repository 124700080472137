export default class Utils {
    static async drawFinalImage(flagElements,canvas) {
        console.log('ok')
        if(!canvas)
            return;
        console.log('ok 2')
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (var singleElement in flagElements) {
            console.log(flagElements[singleElement]['image_url'])
            if (flagElements[singleElement]['image_url']) {
                try {
                    await (async (imageUrl) => {
                        const image = await this.loadImage(imageUrl);
                        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                        console.log('ON DESSINE POUR ' + imageUrl);
                      })(process.env.BASE_URL + process.env.VUE_APP_DIR_FLAG_ELEMENTS + encodeURIComponent(flagElements[singleElement]['image_url']));

                } catch (error) {
                // Gérez toute erreur inattendue ici
                console.error('Une erreur inattendue s\'est produite :', error);
                }
                //console.log(process.env.BASE_URL + process.env.VUE_APP_DIR_FLAG_ELEMENTS + flagElements[singleElement]['image_url'])
                //this.drawSingleImage(process.env.BASE_URL + process.env.VUE_APP_DIR_FLAG_ELEMENTS + flagElements[singleElement]['image_url'],ctx)
            }
        }
    }
    static loadImage(imageUrl) {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = imageUrl;
      
          image.onload = () => resolve(image);
          image.onerror = reject;
        });
    }
    
    static  getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ")
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    static deleteAllCookies() {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
}